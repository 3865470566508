import React, { useState, useEffect } from "react";
import { db, auth } from "../../Firebase-config";
import { Modal } from "semantic-ui-react";
import { Alert } from "react-bootstrap";
function ModalProductView({
  open,
  setOpen,
  imageURL,
  sprice,
  title,
  price,
  desc,

  addToCart,
  individualProduct,
}) {
  const [msg, setMsg] = useState(null);
  const handleAddToCart = () => {
    addToCart(individualProduct);
    setMsg("Added to Cart!");
  };
  return (
    <>
      <Modal
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        class="modal fade"
        style={{
          width: "100%",
          marginTop: "2vh",
          height: "90%",
          backgroundColor: "transparent",
        }}
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => setOpen(false)}
              ></button>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-md-5 col-sm-12 col-xs-12">
                  <div class="tab-content quickview-big-img">
                    <div id="pro-1" class="tab-pane fade show active">
                      <img src={imageURL} alt="" />
                    </div>
                  </div>
                </div>
                <div class="col-md-7 col-sm-12 col-xs-12">
                  <div class="product-details-content quickview-content">
                    <h2>{title}</h2>
                    <div class="product-details-price">
                      <span>₹{sprice}.00 </span>
                      <span class="old">₹{price}.00 </span>
                    </div>
                    <div class="pro-details-rating-wrap">
                      <div class="pro-details-rating">
                        <i class="fa fa-star-o yellow"></i>
                        <i class="fa fa-star-o yellow"></i>
                        <i class="fa fa-star-o yellow"></i>
                        <i class="fa fa-star-o"></i>
                        <i class="fa fa-star-o"></i>
                      </div>
                      <span>3 Reviews</span>
                    </div>
                    <p style={{ fontSize: "12px", lineHeight: "18px" }}>
                      {desc}
                    </p>

                    <div class="pro-details-quality">
                      <div class="pro-details-cart btn-hover">
                        <a
                          style={{ cursor: "pointer" }}
                          onClick={handleAddToCart}
                        >
                          Add To Cart
                        </a>
                      </div>
                      <div class="pro-details-wishlist">
                        {msg && <Alert variant="success">{msg}</Alert>}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default ModalProductView;
