import React, { useState } from "react";
import { Button, Modal, Form } from "semantic-ui-react";
import { useNavigate, Link } from "react-router-dom";
import { auth } from "../../Firebase-config";
import { Alert } from "react-bootstrap";
import ModalRegister from "../Authentications/ModalRegister";

import { signInWithEmailAndPassword } from "firebase/auth";

function ModalLogin({ open, setOpen }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loader, setLoader] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [loaderBtn, setLoaderBtn] = useState(false);
  const navigate = useNavigate();
  const handleSignIn = (e) => {
    e.preventDefault();
    setLoader("Verifying credentials...");
    setLoaderBtn(true);
    signInWithEmailAndPassword(auth, email, password)
      .then(() => {
        setSuccessMsg("Login Successful ! Please wait...");
        setLoader("");
        setEmail("");
        setPassword("");
        setErrorMsg("");
        setLoaderBtn(true);

        setTimeout(() => {
          setSuccessMsg("");
          setOpen(false);
          setLoaderBtn(false);
          window.location.reload();
        }, 3000);
      })
      .catch((error) => {
        setErrorMsg(error.message);
        setLoader("");
        setLoaderBtn(false);
      });
  };

  const [openR, setOpenR] = useState(false);
  const [modalRegister, setModalApplyR] = useState({});
  const handleRegister = () => {
    setOpenR(true);
    setModalApplyR();
  };
  return (
    <>
      <Modal
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        class="modal fade"
        style={{
          width: "100%",
          height: "100%",
          marginTop: "-5vh",
          backgroundColor: "transparent",
        }}
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div
              class="modal-header"
              style={{ backgroundColor: "green", color: "white" }}
            >
              <h5 class="modal-title" id="exampleModalLabel">
                Login to Your Account
              </h5>
              <button
                type="button"
                style={{
                  color: "white",
                  backgroundColor: "green",
                  border: "1px solid White",
                }}
                onClick={() => setOpen(false)}
                data-bs-dismiss="modal"
              >
                X
              </button>
            </div>
            <div class="modal-body">
              <div class="card mb-3">
                <div class="card-body">
                  <div
                    class="pb-2"
                    style={{
                      marginBottom: "15px",
                      textAlign: "center",
                    }}
                  ></div>
                  {successMsg && (
                    <Alert
                      variant="success"
                      style={{
                        fontSize: "13.5px",
                        textAlign: "center",
                      }}
                    >
                      {successMsg}
                    </Alert>
                  )}
                  {errorMsg && (
                    <Alert
                      variant="danger"
                      style={{
                        fontSize: "13.5px",
                        textAlign: "center",
                      }}
                    >
                      {errorMsg}
                    </Alert>
                  )}
                  <form
                    class="row g-3 needs-validation"
                    onSubmit={handleSignIn}
                  >
                    <div class="col-12">
                      <label for="yourUsername" class="form-label">
                        Email
                      </label>
                      <div class="input-group has-validation">
                        <input
                          type="email"
                          value={email}
                          placeholder="Enter your registered email"
                          onChange={(e) => setEmail(e.target.value)}
                          class="form-control"
                          required
                        />
                        <span class="input-group-text" id="inputGroupPrepend">
                          <i class="fa fa-envelope" aria-hidden="true"></i>
                        </span>
                      </div>
                    </div>

                    <div class="col-12">
                      <label for="yourPassword" class="form-label">
                        Password
                      </label>
                      <div class="input-group has-validation">
                        <input
                          type="password"
                          value={password}
                          placeholder="Enter your password"
                          onChange={(e) => setPassword(e.target.value)}
                          class="form-control"
                          required
                        />
                        <span class="input-group-text" id="inputGroupPrepend">
                          <i class="fa fa-eye" aria-hidden="true"></i>
                        </span>
                      </div>
                    </div>

                    <div class="col-12">
                      <Link to="" style={{ fontSize: "12px", color: "green" }}>
                        Forgot Password
                      </Link>
                    </div>
                    <div class="col-12 my-4">
                      {loaderBtn === false ? (
                        <>
                          <Button
                            style={{
                              width: "100%",
                              backgroundColor: "green",
                              color: "white",
                            }}
                            type="submit"
                          >
                            Login
                          </Button>
                        </>
                      ) : (
                        <>
                          <button
                            class="ui loading button"
                            style={{ height: "37px", width: "100%" }}
                          >
                            Loading
                          </button>
                        </>
                      )}
                    </div>
                    <div class="col-12">
                      <p class="small mb-0" style={{ textAlign: "center" }}>
                        Don't have account?{" "}
                        <a
                          style={{ color: "green", cursor: "pointer" }}
                          onClick={() => handleRegister()}
                        >
                          Register
                        </a>
                        {openR && (
                          <ModalRegister
                            open={openR}
                            setOpen={setOpenR}
                            // handleDelete={handleDelete}
                            {...modalRegister}
                          />
                        )}
                      </p>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default ModalLogin;
