import "./App.css";
import { Routes, Route } from "react-router-dom";
import Navbar from "./Components/Navbar";
import Navbar2 from "./Components/Admin/Navbar";
import Home from "./Components/Home";
import Register from "./Components/Authentications/Register";
import Login from "./Components/Authentications/Login";
import AddProducts from "./Components/Admin/AddProducts";
import Products from "./Components/Products/Products";

import { Cart } from "./Components/Cart/Cart";

import Profile from "./Components/Authentications/Profile";
import Orders from "./Components/Admin/Orders";
import Footer from "./Components/Footer";
import Dashboard from "./Components/Admin/Dashboard";
import ProductsList from "./Components/Admin/Products";
import About from "./Components/About";
import Contact from "./Components/Contact";

import OrderHistory from "./Components/OrderHistory";
import OrderedProduct from "./Components/OrderedProduct";
import OrderDetails from "./Components/Admin/OrderDetails";
import { CartProducts } from "./Components/Cart/CartProducts";

import TermCondition from "./Components/TermCondition";
import ProductDetails from "./Components/Products/ProductDetails";
import TermsandConditions from "./Components/Policy/TermsandConditions";
import PrivacyPolicy from "./Components/Policy/PrivacyPolicy";
import PaymentForm from "./Components/Cart/PaymentForm";
import Address from "./Components/Cart/Address";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route
          path="/"
          element={
            <>
              <Navbar />
              <Home />
              <Footer />
            </>
          }
        />
        <Route
          path="/register_auth"
          element={
            <>
              <Navbar />
              <Register />
              <Footer />
            </>
          }
        />
        <Route
          path="/login_auth"
          element={
            <>
              <Navbar />
              <Login />
              <Footer />
            </>
          }
        />

        <Route
          path="/products"
          element={
            <>
              <Navbar />
              <Products />
              <Footer />
            </>
          }
        />

        <Route
          path="/terms_Conditions "
          element={
            <>
              <Navbar />
              <TermCondition />
              <Footer />
            </>
          }
        />

        <Route
          path="/cartItems"
          element={
            <>
              <Navbar />
              <Cart />
              <Footer />
            </>
          }
        />

        <Route
          path="/update_address"
          element={
            <>
              <Navbar />
              <Address />
              <Footer />
            </>
          }
        />

        <Route
          path="/product/:id"
          element={
            <>
              <Navbar />
              <ProductDetails />
              <Footer />
            </>
          }
        />

        <Route
          path="/my_orders"
          element={
            <>
              <Navbar />
              <OrderHistory />
              <Footer />
            </>
          }
        />

        <Route
          path="/order_item/:id"
          element={
            <>
              <Navbar />
              <OrderedProduct />
              <Footer />
            </>
          }
        />

        <Route
          path="/my_profile"
          element={
            <>
              <Navbar />
              <Profile />
              <Footer />
            </>
          }
        />

        <Route
          path="/aboutus"
          element={
            <>
              <Navbar />
              <About />
              <Footer />
            </>
          }
        />

        <Route
          path="/contact_us"
          element={
            <>
              <Navbar />
              <Contact />
              <Footer />
            </>
          }
        />

        <Route
          path="/terms__conditions"
          element={
            <>
              <Navbar />
              <TermsandConditions />
              <Footer />
            </>
          }
        />

        <Route
          path="/privacy_policy"
          element={
            <>
              <Navbar />
              <PrivacyPolicy />
              <Footer />
            </>
          }
        />

        <Route
          path="/payment_page/:id"
          element={
            <>
              <Navbar />
              <PaymentForm />
              <Footer />
            </>
          }
        />

        {/* ADMIN CONTROLS */}

        <Route
          path="/adminDashboard"
          element={
            <>
              <Navbar2 />
              <Dashboard />
            </>
          }
        />
        <Route
          path="/productslist"
          element={
            <>
              <Navbar2 />
              <ProductsList />
            </>
          }
        />

        <Route
          path="/addProducts"
          element={
            <>
              <Navbar2 />
              <AddProducts />
            </>
          }
        />

        <Route
          path="/current_orders_"
          element={
            <>
              <Navbar2 />
              <Orders />
            </>
          }
        />

        <Route
          path="/admin_order_details/:id"
          element={
            <>
              <Navbar2 />
              <OrderDetails />
            </>
          }
        />

        {/* ADMIN CONTROLS */}
      </Routes>
    </div>
  );
}

export default App;
