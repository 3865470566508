import React, { useState } from "react";
import { Button, Form } from "semantic-ui-react";
import { auth, db } from "../../Firebase-config";
import { useNavigate, Link } from "react-router-dom";
import { Alert } from "react-bootstrap";
import { createUserWithEmailAndPassword } from "firebase/auth";
import GoToTop from "../../GoToTop";
function Register() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loader, setLoader] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [loaderBtn, setLoaderBtn] = useState(false);
  const navigate = useNavigate();

  const handleSignUp = (e) => {
    e.preventDefault();
    setLoader("Creating Account...");
    setLoaderBtn(true);
    createUserWithEmailAndPassword(auth, email, password)
      .then((credentials) => {
        db.collection("Allusers")
          .doc(credentials.user.uid)
          .set({
            name: name,
            email: email,
            password: password,
            uid: credentials.user.uid,
          })
          .then(() => {
            setSuccessMsg("Hurray! Your account has been created successfully");
            setLoader("");
            setName("");
            setEmail("");
            setPassword("");
            setErrorMsg("");
            setLoaderBtn(true);

            setTimeout(() => {
              setSuccessMsg("");
              navigate("/");
              setLoaderBtn(false);
            }, 3000);
          })
          .catch((error) => {
            setErrorMsg(error.message);
            setLoader("");
            setLoaderBtn(false);
          });
      })
      .catch((error) => {
        setErrorMsg(error.message);
        setLoader("");
      });
  };

  return (
    <>
      <GoToTop />
      {/* <div class="login-register-area pt-130 pb-100">
        <div class="container" style={{ marginTop: "15vh" }}>
          <div class="form-structor col-lg-7 col-md-12 ms-auto me-auto">
            <div class="signup">
              <h2 class="form-title" id="signup">
                Sign up
              </h2>
              {errorMsg && <Alert variant="danger">{errorMsg}</Alert>}
              {successMsg && <Alert variant="success">{successMsg}</Alert>}
              <form action="#" method="post" onSubmit={handleSignUp}>
                <div class="form-holder">
                  <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    class="input"
                    placeholder="Name"
                  />
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    class="input"
                    placeholder="Email"
                  />
                  <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    class="input"
                    placeholder="Password"
                  />
                </div>
                {loader && (
                  <p
                    style={{
                      color: "red",
                      padding: "6px",
                      fontSize: "12px",
                    }}
                  >
                    {loader}
                  </p>
                )}
                {!name ? (
                  <button class="submit-btn" disabled>
                    Sign up
                  </button>
                ) : (
                  <button class="submit-btn" type="submit">
                    Sign up
                  </button>
                )}
              </form>
              <h5
                style={{
                  textAlign: "center",
                  cursor: "pointer",
                  color: "white",
                }}
              >
                <Link
                  style={{
                    color: "black",
                  }}
                  to="/login_auth"
                >
                  Login
                </Link>
              </h5>
            </div>
          </div>
        </div>
      </div> */}
      <section class="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">
              <div class="card mb-3">
                <div class="card-body">
                  <div
                    class="pb-2"
                    style={{ marginBottom: "15px", textAlign: "center" }}
                  >
                    <h5 class="card-title text-center pb-0 fs-3">
                      Create a New Account
                    </h5>
                  </div>
                  {successMsg && (
                    <Alert
                      variant="success"
                      style={{ fontSize: "13.5px", textAlign: "center" }}
                    >
                      {successMsg}
                    </Alert>
                  )}
                  {errorMsg && (
                    <Alert
                      variant="danger"
                      style={{ fontSize: "13.5px", textAlign: "center" }}
                    >
                      {errorMsg}
                    </Alert>
                  )}
                  <form
                    class="row g-3 needs-validation"
                    onSubmit={handleSignUp}
                  >
                    <div class="col-12">
                      <label for="yourUsername" class="form-label">
                        Name
                      </label>
                      <div class="input-group has-validation">
                        <input
                          type="text"
                          value={name}
                          placeholder="Enter your name"
                          onChange={(e) => setName(e.target.value)}
                          class="form-control"
                          required
                        />
                        <span class="input-group-text" id="inputGroupPrepend">
                          <i class="fa fa-user" aria-hidden="true"></i>
                        </span>
                      </div>
                    </div>

                    <div class="col-12">
                      <label for="yourUsername" class="form-label">
                        Email
                      </label>
                      <div class="input-group has-validation">
                        <input
                          type="email"
                          value={email}
                          placeholder="Enter your registered email"
                          onChange={(e) => setEmail(e.target.value)}
                          class="form-control"
                          required
                        />
                        <span class="input-group-text" id="inputGroupPrepend">
                          <i class="fa fa-envelope" aria-hidden="true"></i>
                        </span>
                      </div>
                    </div>

                    <div class="col-12">
                      <label for="yourPassword" class="form-label">
                        Password
                      </label>
                      <div class="input-group has-validation">
                        <input
                          type="password"
                          value={password}
                          placeholder="Enter your password"
                          onChange={(e) => setPassword(e.target.value)}
                          class="form-control"
                          required
                        />
                        <span class="input-group-text" id="inputGroupPrepend">
                          <i class="fa fa-eye" aria-hidden="true"></i>
                        </span>
                      </div>
                    </div>

                    <div class="col-12 my-4">
                      {loaderBtn === false ? (
                        <>
                          <Button
                            style={{
                              width: "100%",
                              backgroundColor: "green",
                              color: "white",
                            }}
                            type="submit"
                          >
                            Register
                          </Button>
                        </>
                      ) : (
                        <>
                          <button
                            class="ui loading button"
                            style={{ height: "37px", width: "100%" }}
                          >
                            Loading
                          </button>
                        </>
                      )}
                    </div>
                    <div class="col-12">
                      <p class="small mb-0" style={{ textAlign: "center" }}>
                        Already have an account?{" "}
                        <Link to="/login_auth">Login</Link>
                      </p>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <br></br> <br></br> <br></br>
    </>
  );
}

export default Register;
