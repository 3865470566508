import React from "react";
import GoToTop from "../GoToTop";
import PicA from "./assets/img/organic.png";
import PicB from "./assets/img/masala.png";
function About() {
  return (
    <>
      <GoToTop />

      <div class="we-help-section">
        <div class="container">
          <div class="row justify-content-between">
            <div class="col-lg-7 mb-5 mb-lg-0">
              <div class="imgs-grid">
                <div class="grid grid-1">
                  <img
                    src="https://i.pinimg.com/564x/47/cb/c8/47cbc8767eb5142e52dcc5d7a62a4af7.jpg"
                    alt="axomtea.in"
                  />
                </div>
                <div class="grid grid-2">
                  <img
                    src="https://i.pinimg.com/564x/fe/44/92/fe4492dde28884679f47be25c067986d.jpg"
                    alt="axomtea.in"
                  />
                </div>
                <div class="grid grid-3">
                  <img
                    style={{ border: "8px solid white" }}
                    src="https://i.pinimg.com/564x/85/b3/87/85b387890f08201651c603a09ae9d95c.jpg"
                    alt="axomtea.in"
                  />
                </div>
              </div>
            </div>
            <div class="col-lg-5 ps-lg-5">
              <h2 class="section-title mb-4">Who We Are ?</h2>
              <p style={{ textAlign: "justify" }}>
                Welcome to <b>AXOM TEA</b>, where the artistry of tradition
                converges with the skill of craftsmanship in every delightful
                sip. At <b>AXOM TEA</b>, we take immense pride in the meticulous
                process of handcrafting our organic teas, employing time-honored
                methods that have been passed down through generations. This
                commitment ensures a harmonious blend of authenticity and
                exceptional flavor in every cup.
                <br></br>
                <br></br>
                Immerse yourself in the essence of nature and tradition as you
                explore our thoughtfully curated selection of teas. We source
                the finest organic tea leaves from pristine regions,
                meticulously selecting each leaf to guarantee a tea-drinking
                experience that transcends the ordinary.
                <br></br>
                <br></br>
                Our dedication to quality and sustainability is woven into the
                fabric of <b>AXOM TEA</b>. From the moment the tea leaves are
                plucked to the careful blending process, we prioritize
                authenticity and the preservation of the environment.
                <br></br>
                <br></br>
                Embark on a journey with us and discover the rich tapestry of
                flavors that our teas have to offer. AXOM TEA invites you to
                savor the perfect harmony of tradition and craftsmanship in
                every cup, elevating your tea-drinking experience to new
                heights.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default About;
