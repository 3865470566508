import React from "react";
import { Modal } from "semantic-ui-react";
import { Link } from "react-router-dom";
function ConfirmPaymentModal({ open, setOpen }) {
  return (
    <>
      <Modal
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        class="modal fade"
        style={{
          width: "100%",
          marginTop: "8vh",
          height: "90%",
          backgroundColor: "transparent",
        }}
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div
              class="modal-header"
              style={{ backgroundColor: "orange", color: "white" }}
            >
              Payment Confirmation Pending
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                style={{ backgroundColor: "white" }}
                onClick={() => setOpen(false)}
              ></button>
            </div>
            <div class="modal-body" style={{ textAlign: "center" }}>
              <h3 style={{ color: "green" }}>
                Thank you! We will verify your payment and dispatch your order
                soon...
              </h3>
              <i>
                If your order is pending for more than 24 hrs, we will cancel
                your order automatically.
              </i>
            </div>
            <div className="modal-footer">
              <Link to="/my_orders">
                <button className="btn btn-success">Confirm</button>
              </Link>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default ConfirmPaymentModal;
