import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Products from "./Products/Products";
import GoToTop from "../GoToTop";

import Truck from "./assets/img/truck.svg";
import Bag from "./assets/img/bag.svg";
import Support from "./assets/img/support.svg";
import Return from "./assets/img/return.svg";
import Team from "./Team";
import About from "./About";
import Product2 from "./Products/Product2";

function Home() {
  const navigate = useNavigate();
  let Men = "Men";
  let Women = "Women";
  let Traditional = "Traditional";
  let Western = "Western";
  let Kids = "Kid";
  return (
    <>
      <GoToTop />

      {/* <!-- Start Hero Section --> */}
      {/*  <div class="hero">
        <div class="container" style={{ textAlign: "left", marginTop: "10vh" }}>
         <div class="row justify-content-between">
            <div class="col-lg-5 order-2 order-lg-1 my-2">
              <div class="intro-excerpt">
                <h1 style={{ color: "#f7a2bb" }}>
                  Mine Jewels{" "}
                  <span style={{ color: "#fff" }} class="d-block">
                    Cheers to self love
                  </span>
                </h1>

                <p class="mb-4">
                  A tribute to self-love. Adorn in elegance, embody empowerment.
                  Each piece mirrors your journey, a reminder of your
                  uniqueness. Celebrate the extraordinary you.
                </p>
                <p>
                  <Link to="/products" class="btn btn-secondary me-2">
                    Shop Now
                  </Link>
                  <Link to="/products" class="btn btn-white-outline">
                    Explore
                  </Link>
                </p>
              </div>
            </div>
            <div class="col-lg-7 order-1 order-lg-2 my-2">
              <div class="hero-img-wrap">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/mine-14602.appspot.com/o/Website%2Fhome.png?alt=media&token=c3c660d7-b20d-4a0d-874c-1b44404055c4"
                  class="img-fluid"
                />
              </div>
            </div>
          </div> 
          
        </div>
      </div>*/}
      <div class="content" style={{ textAlign: "left", marginTop: "5vh" }}>
        <img
          src="https://firebasestorage.googleapis.com/v0/b/assamtea-ea96d.appspot.com/o/Mainsite%2Fpc.svg?alt=media&token=9db9ec5f-2615-4724-9bba-6e72734d1954"
          alt="home"
          style={{ width: "100%" }}
          id="PCHomeBG"
        />
        <img
          src="https://firebasestorage.googleapis.com/v0/b/assamtea-ea96d.appspot.com/o/Mainsite%2Fmb.svg?alt=media&token=8f475531-c628-48e9-8eec-6e4e9953be76"
          alt="home"
          style={{ width: "100%", marginTop: "7vh" }}
          id="MBHomeBG"
        />
      </div>
      {/* <!-- End Hero Section --> */}

      {/* <!-- Start Product Section --> */}
      <div class="product-section">
        <div class="container">
          <div class="row">
            <div class="col-md-12 col-lg-4 mb-5 mb-lg-0">
              <h2 class="mb-4 section-title">New Arrivals</h2>
              <p class="mb-4" id="prtext">
                Savor the moment with our tantalizing new tea arrivals—where
                every steep unveils a symphony of flavors.<br></br>
                <br></br>Indulge in the art of tea with our exquisite new
                arrivals. Elevate your senses with enchanting blends and
                discover a world of flavors in every cup
              </p>
              <p>
                <Link to="/products" class="btn">
                  Explore
                </Link>
              </p>
            </div>

            <Product2 />
          </div>
        </div>
      </div>
      {/* <!-- End Product Section --> */}

      {/* <!-- Start Why Choose Us Section --> */}
      {/* <div class="why-choose-section">
        <div class="container">
          <div class="row justify-content-between">
            <div class="col-lg-6">
              <h2 class="section-title">Why Choose Us</h2>
              <p>
                When you choose MINE, you're embracing unmatched creativity and
                passion infused into every piece of handmade jewelry we offer.
                Experience the dedication and artistry that sets us apart.
              </p>

              <div class="row my-5">
                <div class="col-6 col-md-6">
                  <div class="feature">
                    <div class="icon">
                      <img src={Truck} alt="Image" class="imf-fluid" />
                    </div>
                    <h3>Fast &amp; Free Shipping</h3>
                    <p>
                      Swiftly delivered, no-cost shipping awaits. <br></br>-
                      <i>(Terms and conditions applied)</i>
                    </p>
                  </div>
                </div>

                <div class="col-6 col-md-6">
                  <div class="feature">
                    <div class="icon">
                      <img src={Bag} alt="Image" class="imf-fluid" />
                    </div>
                    <h3>Easy to Shop</h3>
                    <p>
                      Seamless shopping experience, hassle-free and convenient.
                    </p>
                  </div>
                </div>

                <div class="col-6 col-md-6">
                  <div class="feature">
                    <div class="icon">
                      <img src={Support} alt="Image" class="imf-fluid" />
                    </div>
                    <h3>24/7 Support</h3>
                    <p>Always here to help, anytime, anywhere you are.</p>
                  </div>
                </div>

                <div class="col-6 col-md-6">
                  <div class="feature">
                    <div class="icon">
                      <img src={Return} alt="Image" class="imf-fluid" />
                    </div>
                    <h3>Hassle Free Returns</h3>
                    <p>
                      Hassle Free Returns <br></br>-
                      <i>(Terms and conditions applied)</i>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-5">
              <div class="img-wrap">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/mine-14602.appspot.com/o/Website%2Fvalentina-jorio-zg3oZjdSoDY-unsplash.jpg?alt=media&token=76e165a6-eb92-4cd3-b313-4eb9de583f54"
                  alt="Image"
                  class="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* <!-- End Why Choose Us Section --> */}

      <About />

      {/* <!-- Start Testimonial Slider --> */}
      {/* <div class="testimonial-section">
        <div class="container">
          <div class="row">
            <div class="col-lg-7 mx-auto text-center">
              <h2 class="section-title">Testimonials</h2>
            </div>
          </div>
          <div className="row my-3">
            <div className="col-lg-4 my-3">
              <div
                class="row"
                id="testicard"
                style={{
                  backgroundColor: "white",
                  padding: "10px",
                  margin: "1px",
                  borderRadius: "8px",
                }}
              >
                <div className="col-8" id="testiText">
                  <p id="testimonialP">
                    <i>
                      Beautifully designed jewellery by @mine.jewels_in{" "}
                      <br></br>
                      The chain is so beautiful and the earrings, which was a
                      free gift by them, has melted my heart the packaging was
                      also very beautiful including the cute card.
                    </i>
                  </p>
                </div>
                <div className="col-4">
                  <img
                    style={{ width: "100%", borderRadius: "8px" }}
                    src="https://firebasestorage.googleapis.com/v0/b/mine-14602.appspot.com/o/Testimonial%2FMrigakshi%C2%A0Sarma.jpg?alt=media&token=fd10f8f5-7208-41a8-bba9-1e8720dded59"
                    alt="sq-sample10"
                  />
                </div>
                <p id="testiname">
                  Mrigakshi Sarma{" "}
                  <span>
                    - <i>Guwahati</i>
                  </span>
                </p>
              </div>
            </div>

            <div className="col-lg-4 my-3">
              <div
                class="row"
                id="testicard"
                style={{
                  backgroundColor: "white",
                  padding: "10px",
                  margin: "1px",
                  borderRadius: "8px",
                }}
              >
                <div className="col-8" id="testiText">
                  <p id="testimonialP">
                    <i>
                      I got the men's stainless steel bracelet, and seriously,
                      I've been getting a bunch of compliments for it. Currently
                      waiting for new bracelet designs for men!
                    </i>
                  </p>
                </div>
                <div className="col-4">
                  <img
                    style={{ width: "100%", borderRadius: "8px" }}
                    src="https://firebasestorage.googleapis.com/v0/b/mine-14602.appspot.com/o/Testimonial%2FRudra.jpg?alt=media&token=d7a4db22-f77c-441e-b370-439d1517bcf0"
                    alt="sq-sample10"
                  />
                </div>
                <p id="testiname">
                  Rudra Nath{" "}
                  <span>
                    - <i>Barpeta</i>
                  </span>
                </p>
              </div>
            </div>

            <div className="col-lg-4 my-3">
              <div
                class="row"
                id="testicard"
                style={{
                  backgroundColor: "white",
                  padding: "10px",
                  margin: "1px",
                  borderRadius: "8px",
                }}
              >
                <div className="col-8" id="testiText">
                  <p id="testimonialP">
                    <i>
                      Recently I just reached to the page @mine.jewels in for
                      this custom bracelet.And she is best, She will listen to
                      your idea patiently she will make your thought in real I
                      had the best experience with her she is so sweet that she
                      was answering my every text and i have received it in
                      the perfect time.
                    </i>
                  </p>
                </div>
                <div className="col-4">
                  <img
                    style={{ width: "100%", borderRadius: "8px" }}
                    src="https://firebasestorage.googleapis.com/v0/b/mine-14602.appspot.com/o/Testimonial%2F1.jpg?alt=media&token=724edcc9-fb36-4690-aa02-3bace581023a"
                    alt="sq-sample10"
                  />
                </div>
                <p id="testiname">
                  Gunina Pandey{" "}
                  <span>
                    - <i>Uttar Pradesh</i>
                  </span>
                </p>
              </div>
            </div>

            <div className="col-lg-4 my-3">
              <div
                class="row"
                id="testicard"
                style={{
                  backgroundColor: "white",
                  padding: "10px",
                  margin: "1px",
                  borderRadius: "8px",
                }}
              >
                <div className="col-8" id="testiText">
                  <p id="testimonialP">
                    <i>
                      I absolutely adore the necklace I got from Mine Jewels,
                      It's so elegant and beautifully crafted. The design is
                      stunning, and the quality is top-notch. I receive
                      compliments every time I wear it. I'm really happy with my
                      purchase and would definitely recommend Mine Jewels to
                      anyone looking for exquisite jewelry.
                    </i>
                  </p>
                </div>
                <div className="col-4">
                  <img
                    style={{ width: "100%", borderRadius: "8px" }}
                    src="https://firebasestorage.googleapis.com/v0/b/mine-14602.appspot.com/o/Testimonial%2F27.jpg?alt=media&token=ea1d019f-d476-4834-9f12-065c5b7bbaa4"
                    alt="sq-sample10"
                  />
                </div>
                <p id="testiname">
                  Minakshi Dey{" "}
                  <span>
                    - <i>Kolkata</i>
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {/* <!-- End Testimonial Slider --> */}

      {/* <!-- Team --> */}
    </>
  );
}

export default Home;
