import React from "react";
import { Button } from "semantic-ui-react";
import { Link } from "react-router-dom";
import GoToTop from "../../GoToTop";
function Dashboard() {
  return (
    <>
      <GoToTop />
      <div
        className="container "
        style={{ textAlign: "left", marginTop: "13vh" }}
      >
        <div className="row">
          <div className="col-lg-4">
            <div class="card">
              <div class="card-body">
                <h4 class="card-title">Products</h4>
                <p class="card-text">Get list of all the products here</p>
                <Link to="/productslist" class="btn btn-success">
                  Redirect
                </Link>
              </div>
            </div>
          </div>

          <div className="col-lg-4">
            <div class="card">
              <div class="card-body">
                <h4 class="card-title">Orders</h4>
                <p class="card-text">Get list of all the placed orders here</p>
                <Link to="/current_orders_" class="btn btn-success">
                  Redirect
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-4"></div>
          <div className="col-lg-4"></div>
        </div>
      </div>
    </>
  );
}

export default Dashboard;
