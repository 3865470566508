import React, { useState, useEffect } from "react";
import { db, auth } from "../../Firebase-config";
import ModaDeleteOrder from "./ModalDeleteOrder";
import { Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import GoToTop from "../../GoToTop";
import { onSnapshot } from "firebase/firestore";
import ModalAddress from "./ModalAddress";

const Orders = () => {
  const [userData, setUserData] = useState(null);
  const userId = auth.currentUser ? auth.currentUser.uid : null;
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchUserData = async () => {
      if (userId) {
        try {
          const userDocRef = db.collection("Allusers").doc(userId);
          const userDoc = await userDocRef.get();

          if (userDoc.exists) {
            const userData = userDoc.data();
            setUserData(userData);
          } else {
            navigate("/");
          }
        } catch (error) {
          console.log("Error getting user document:", error);
        }
      }
    };

    // Call the fetchUserData function
    fetchUserData();
  }, [userId]);

  //Orders Fetch
  const [data, setData] = useState([]);
  useEffect(() => {
    const unsub = onSnapshot(
      db.collection("OrderHistory").orderBy("timestamp", "desc"),
      (snapshot) => {
        let list = [];
        snapshot.docs.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() });
        });
        setData(list);
        setIsLoading(false);
      },

      (error) => {
        console.error(error);
        setIsLoading(false);
      }
    );
    return () => {
      unsub();
    };
  }, []);

  /////////////////Filter

  const [searchTerm, setSearchTerm] = useState("");
  const [searchBy, setSearchBy] = useState("name");

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSearchByChange = (event) => {
    setSearchBy(event.target.value);
  };

  const filteredData = data.filter((item) =>
    item[searchBy].toLowerCase().includes(searchTerm.toLowerCase())
  );

  const formatTimestamp = (posted) => {
    const date = posted.toDate();
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    return date.toLocaleString("en-US", options);
  };

  const [openD, setOpenD] = useState(false);
  const [modaldelete, setModalD] = useState({});

  const handleDeleteOrder = (item) => {
    setOpenD(true);
    setModalD(item);
  };

  const [openAa, setOpenAa] = useState(false);
  const [modaladdress, setModalAa] = useState({});

  const handleModalAddress = (item) => {
    setOpenAa(true);
    setModalAa(item);
  };

  return (
    <>
      <GoToTop />
      <div className="container" style={{ marginTop: "14vh" }}>
        <h4 style={{ textAlign: "left" }}>Order History</h4>

        <Table
          responsive
          striped
          size="md"
          bordered
          style={{ marginTop: "4px", fontSize: "13px" }}
        >
          <thead id="adminthead">
            <tr>
              <th>#</th>
              <th>Date & Time</th>
              <th>Customer Name</th>
              <th>Customer Email</th>
              <th> Contact</th>
              <th>Customer Address</th>
              <th>Total Amount</th>
              <th>Payment Status/ID</th>
              <th>Delivery Status</th>
              <th style={{ textAlign: "center" }}>Action</th>
            </tr>
          </thead>
          <tbody>
            {data.length > 0 ? (
              <>
                {filteredData.map((item, index) => (
                  <tr key={item.id}>
                    <th scope="row" style={{ width: "3vh" }}>
                      {index + 1}
                    </th>
                    <td
                      style={{
                        minWidth: "21vh",
                        color: "grey",
                      }}
                    >
                      {item.timestamp && formatTimestamp(item.timestamp)}
                    </td>
                    <td
                      style={{
                        minWidth: "18vh",
                      }}
                    >
                      {item.name}
                    </td>
                    <td
                      style={{
                        minWidth: "20vh",

                        textAlign: "left",
                      }}
                    >
                      {item.email}
                    </td>

                    <td style={{ minWidth: "15vh" }}>{item.phone}</td>

                    <td
                      style={{
                        minWidth: "12vh",
                        color: "grey",
                        cursor: "pointer",
                      }}
                    >
                      <a onClick={() => handleModalAddress(item)}>
                        View Address
                      </a>
                      {openAa && (
                        <ModalAddress
                          open={openAa}
                          setOpen={setOpenAa}
                          {...modaladdress}
                        />
                      )}
                    </td>
                    <td style={{ minWidth: "15vh", fontWeight: "bold" }}>
                      ₹{item.TtotalPrice}.00
                    </td>
                    <td
                      style={{
                        minWidth: "13vh",
                        fontWeight: "bold",
                        color:
                          item.status === "Paid"
                            ? "green"
                            : item.status === "Cancelled"
                            ? "red"
                            : "orange",
                      }}
                    >
                      {item.status}
                      <br></br>
                      <i
                        style={{
                          fontSize: "9px",
                          color: "grey",
                          fontWeight: "normal",
                        }}
                      >
                        {item.payId}
                      </i>
                    </td>

                    <td
                      style={{
                        minWidth: "13vh",
                        fontWeight: "bold",
                      }}
                    >
                      {item.status === "Cancelled" ||
                      item.status === "Pending" ? null : (
                        <>
                          <>
                            {(!item.Ashipped || item.Ashipped === "") &&
                            (!item.Atransit || item.Atransit === "") &&
                            (!item.Adelivered || item.Adelivered === "") ? (
                              <i style={{ color: "red", fontWeight: "normal" }}>
                                Ordered
                              </i>
                            ) : null}
                            {item.Ashipped === "active" &&
                            (!item.Atransit || item.Atransit === "") &&
                            (!item.Adelivered || item.Adelivered === "") ? (
                              <i
                                style={{ color: "green", fontWeight: "normal" }}
                              >
                                Shipped
                              </i>
                            ) : null}
                            {item.Ashipped === "active" &&
                            item.Atransit === "active" &&
                            (!item.Adelivered || item.Adelivered === "") ? (
                              <i
                                style={{ color: "green", fontWeight: "normal" }}
                              >
                                In Transit
                              </i>
                            ) : null}
                            {item.Ashipped === "active" &&
                            item.Atransit === "active" &&
                            item.Adelivered === "active" ? (
                              <i
                                style={{ color: "green", fontWeight: "normal" }}
                              >
                                Delivered
                              </i>
                            ) : null}
                          </>
                        </>
                      )}
                    </td>

                    <td
                      style={{
                        minWidth: "10vh",

                        textAlign: "center",
                      }}
                    >
                      {item.status === "Cancelled" ||
                      item.status === "Pending" ? (
                        <>
                          <i
                            disabled
                            style={{
                              fontSize: "17px",
                              color: "grey",
                              margin: "10px",
                              cursor: "not-allowed",
                            }}
                            class="fa fa-pencil"
                            aria-hidden="true"
                          ></i>
                        </>
                      ) : (
                        <>
                          <i
                            onClick={() =>
                              navigate(`/admin_order_details/${item.id}`)
                            }
                            style={{
                              fontSize: "17px",
                              color: "green",
                              margin: "10px",
                              cursor: "pointer",
                            }}
                            class="fa fa-pencil"
                            aria-hidden="true"
                          ></i>
                        </>
                      )}
                      {item.status === "Pending" ||
                      item.status === "Cancelled" ? (
                        <>
                          <i
                            style={{
                              fontSize: "17px",
                              color: "red",
                              margin: "10px",
                              cursor: "pointer",
                            }}
                            onClick={() => handleDeleteOrder(item)}
                            class="fa fa-trash"
                            aria-hidden="true"
                          ></i>
                          {openD && (
                            <ModaDeleteOrder
                              open={openD}
                              setOpen={setOpenD}
                              {...modaldelete}
                            />
                          )}
                        </>
                      ) : (
                        <>
                          <i
                            style={{
                              fontSize: "17px",
                              color: "grey",
                              margin: "10px",
                              cursor: "not-allowed",
                            }}
                            class="fa fa-trash"
                            aria-hidden="true"
                          ></i>
                        </>
                      )}
                    </td>
                  </tr>
                ))}
              </>
            ) : (
              <tr>
                <td colSpan="10" style={{ textAlign: "center", color: "red" }}>
                  No Record Found
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    </>
  );
};

export default Orders;
