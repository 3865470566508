import React, { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Form, Button } from "semantic-ui-react";
import { doc, getDoc, onSnapshot } from "firebase/firestore";
import { auth, db } from "../Firebase-config";
import GoToTop from "../GoToTop";
function OrderedProduct() {
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const UID = params.get("UID");
  const IDD = params.get("IDD");
  const { id } = useParams();
  const [userData, setUserData] = useState("");
  const userId = auth.currentUser ? auth.currentUser.uid : null;
  useEffect(() => {
    const fetchUserData = async () => {
      if (userId) {
        try {
          const userDocRef = db.collection("Allusers").doc(userId);
          const userDoc = await userDocRef.get();

          if (userDoc.exists) {
            const userData = userDoc.data();
            setUserData(userData);
          } else {
            console.log("No user document found!");
          }
        } catch (error) {
          console.log("Error getting user document:", error);
        }
      }
    };

    // Call the functions to fetch the data
    fetchUserData();
  }, [userId]);
  // console.log(user);

  const [documentData, setDocumentData] = useState("");
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const querySnapshot = await db.collectionGroup("ProductsHistory").get();

        querySnapshot.forEach((docSnapshot) => {
          if (docSnapshot.exists && docSnapshot.id === id) {
            const dataWithID = { id: docSnapshot.id, ...docSnapshot.data() };
            setDocumentData(dataWithID);

            // You can also compare the document's timestamp here if needed
            const firestoreTimestamp = docSnapshot.data().postedtime.toDate();

            // Calculate the current time
            const currentTime = new Date();

            // Calculate the time 24 hours ago
            const twentyFourHoursAgo = new Date(
              currentTime.getTime() - 24 * 60 * 60 * 1000
            );

            // Check if the document's timestamp is older than 24 hours
            if (firestoreTimestamp <= twentyFourHoursAgo) {
              setButtonDisabled(true);
            }
          }
        });

        if (!documentData) {
          console.log("No such document!");
        }
      } catch (error) {
        console.error("Error fetching document: ", error);
      }
    };

    fetchData();
  }, [id]);

  const [rating, setRating] = useState(0);
  const [review, setReview] = useState("");
  const handleRatingChange = (e) => {
    setRating(parseInt(e.target.value));
  };

  const handleRatingSubmit = () => {
    // Store the rating in Firestore
    db.collection("Products").doc(UID).collection("Review").doc(id).set({
      rating,
      review,
      id,
    });
  };

  const handleCancelOrder = () => {
    // Store the rating in Firestore
    db.collection("OrderHistory")
      .doc(IDD)
      .collection("ProductsHistory")
      .doc(id)
      .update({
        status: "Cancelled",
        Ashipped: "",
      })
      .then(() => {
        window.location.reload();
      });
  };

  const [data, setData] = useState([]);

  useEffect(() => {
    // Create a reference to the Firestore collection
    const collectionRef = db
      .collection("Products")
      .doc(UID)
      .collection("Review");

    // Set up a real-time listener
    const unsubscribe = collectionRef.onSnapshot((querySnapshot) => {
      const items = querySnapshot.docs.map((doc) => doc.data());
      setData(items);
    });

    // Clean up the listener when the component unmounts
    return () => {
      unsubscribe();
    };
  }, [UID]);

  return (
    <>
      <GoToTop />

      {documentData ? (
        <>
          {/* <!-- Start Hero Section --> */}
          <div class="container" style={{ marginTop: "18vh" }}>
            <h2 style={{ color: "green" }}>Order History</h2>
            <span style={{ fontSize: "12px" }}>
              Order ID - <b>{id}</b>{" "}
            </span>
          </div>

          {/* <!-- End Hero Section --> */}

          {/* <!-- Confirm Cancel --> */}
          <div
            class="modal fade"
            id="exampleModal"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">
                    Cancel Order
                  </h5>
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div class="modal-body">
                  Are you sure you want to delete:{" "}
                  <b style={{ color: "red" }}>{documentData.title}</b>?
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn"
                    style={{ backgroundColor: "grey", border: "none" }}
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </button>
                  <button
                    style={{ backgroundColor: "red", borderColor: "red" }}
                    type="button"
                    onClick={handleCancelOrder}
                    class="btn btn-danger"
                  >
                    Confirm Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="container my-3">
            <div className="row">
              <div className="col-lg-6">
                <div
                  class="row p-2 bg-white border rounded my-2"
                  style={{
                    maxWidth: "100vh",
                    cursor: "pointer",
                    margin: "0.5px",
                  }}
                >
                  <div class="col-3 mt-1" style={{ maxWidth: "20vh" }}>
                    <img
                      class="img-fluid img-responsive rounded product-image"
                      src={documentData.imageURL}
                      style={{
                        maxHeight: "15vh",
                        width: "100%",
                        objectFit: "cover",
                      }}
                    />
                  </div>
                  <div class="col-7 mt-1">
                    <h5 style={{ color: "#545454" }}>
                      {documentData.title}
                      <br /> <br />
                      <span style={{ fontSize: "17px", color: "red" }}>
                        ₹{documentData.TotalProductPrice}.00{" "}
                      </span>
                      <br />
                      <span style={{ color: "grey", fontSize: "13px" }}>
                        Quantity:{" "}
                        <b style={{ color: "black" }}>{documentData.qty}</b>
                      </span>
                    </h5>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div
                  class="row p-2 bg-white border rounded my-2"
                  style={{
                    maxWidth: "100vh",
                    cursor: "pointer",
                    margin: "0.5px",
                  }}
                >
                  <h5>Order Status</h5>
                  <div class="track" style={{ marginTop: "22px" }}>
                    {documentData.status === "Cancelled" ||
                    documentData.status === "Refunded" ? (
                      <>
                        <div class="step cancelled">
                          <span
                            class="icon"
                            style={{ backgroundColor: "red", color: "white" }}
                          >
                            <i class="fa fa-times"></i>
                          </span>{" "}
                          <span class="text" style={{ color: "red" }}>
                            Order Cancelled
                          </span>
                        </div>
                      </>
                    ) : (
                      <>
                        <div class="step active">
                          <span class="icon">
                            <i class="fa fa-check"></i>
                          </span>{" "}
                          <span class="text">Order Placed</span>
                        </div>
                        <div class={`step ${documentData.Ashipped}`}>
                          <span class="icon">
                            <i class="fa fa-user"></i>{" "}
                          </span>{" "}
                          <span class="text"> Shipped</span>{" "}
                        </div>
                        <div class={`step ${documentData.Atransit}`}>
                          <span class="icon">
                            <i class="fa fa-truck"></i>{" "}
                          </span>{" "}
                          <span class="text"> In transit </span>{" "}
                        </div>
                        <div class={`step ${documentData.Adelivered}`}>
                          <span class="icon">
                            <i class="fa fa-home"></i>{" "}
                          </span>{" "}
                          <span class="text">Delivered</span>{" "}
                        </div>
                      </>
                    )}
                  </div>

                  <div class="d-flex flex-column mt-2">
                    <a style={{ fontSize: "11px" }}>
                      Expected Delivery: <b> {documentData.deliveryday}</b>{" "}
                    </a>
                  </div>
                </div>

                {documentData.Ashipped === "active" ? (
                  <></>
                ) : (
                  <>
                    {" "}
                    {documentData.status === null ||
                    documentData.status === undefined ||
                    documentData.status === "" ? (
                      <>
                        <button
                          style={{
                            width: "100%",
                            backgroundColor: "orange",
                            borderColor: "orange",
                          }}
                          className="btn"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                        >
                          Cancel Order
                        </button>
                      </>
                    ) : (
                      <>
                        <div
                          style={{
                            backgroundColor: "#f0f0f0",
                            textAlign: "center",
                            color: "grey",
                            padding: "10px",
                          }}
                        >
                          Cancellation Status:{" "}
                          <b style={{ color: "red" }}>{documentData.status}</b>
                        </div>
                      </>
                    )}
                  </>
                )}
              </div>

              <div className="col-lg-6">
                <div
                  class="row p-2 bg-white border rounded my-2"
                  style={{
                    maxWidth: "100vh",
                    cursor: "pointer",
                    margin: "0.5px",
                  }}
                >
                  <div className="row my-2">
                    <div className="col-7">
                      <h5>Review</h5>
                    </div>
                    <div className="col-5">
                      {!documentData.Adelivered ? (
                        <>
                          <p
                            style={{
                              textAlign: "right",
                              color: "grey",
                              cursor: "not-allowed",
                            }}
                          >
                            <i class="fa fa-pencil"></i> Post Review
                          </p>
                        </>
                      ) : (
                        <>
                          <p
                            style={{ textAlign: "right", color: "orange" }}
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                          >
                            <i class="fa fa-pencil"></i> Post Review
                          </p>
                        </>
                      )}
                    </div>
                  </div>
                  <br></br>
                  {data.map((item) => (
                    <>
                      {item.id && item.id === id ? (
                        <>
                          <Form>
                            <div class="star-rating">
                              {item.rating === 1 ? (
                                <>
                                  <label class="star">&#9733;</label>
                                  <label class="star">&#9733;</label>
                                  <label class="star">&#9733;</label>
                                  <label class="star">&#9733;</label>
                                  <label class="star" style={{ color: "#fc0" }}>
                                    &#9733;
                                  </label>
                                </>
                              ) : item.rating === 2 ? (
                                <>
                                  <label class="star">&#9733;</label>
                                  <label class="star">&#9733;</label>
                                  <label class="star">&#9733;</label>
                                  <label class="star" style={{ color: "#fc0" }}>
                                    &#9733;
                                  </label>
                                  <label class="star" style={{ color: "#fc0" }}>
                                    &#9733;
                                  </label>
                                </>
                              ) : item.rating === 3 ? (
                                <>
                                  <label class="star">&#9733;</label>

                                  <label class="star">&#9733;</label>
                                  <label class="star" style={{ color: "#fc0" }}>
                                    &#9733;
                                  </label>
                                  <label class="star" style={{ color: "#fc0" }}>
                                    &#9733;
                                  </label>
                                  <label class="star" style={{ color: "#fc0" }}>
                                    &#9733;
                                  </label>
                                </>
                              ) : item.rating === 4 ? (
                                <>
                                  <label class="star">&#9733;</label>
                                  <label class="star" style={{ color: "#fc0" }}>
                                    &#9733;
                                  </label>
                                  <label class="star" style={{ color: "#fc0" }}>
                                    &#9733;
                                  </label>
                                  <label class="star" style={{ color: "#fc0" }}>
                                    &#9733;
                                  </label>
                                  <label class="star" style={{ color: "#fc0" }}>
                                    &#9733;
                                  </label>
                                </>
                              ) : item.rating === 5 ? (
                                <>
                                  <label class="star" style={{ color: "#fc0" }}>
                                    &#9733;
                                  </label>
                                  <label class="star" style={{ color: "#fc0" }}>
                                    &#9733;
                                  </label>
                                  <label class="star" style={{ color: "#fc0" }}>
                                    &#9733;
                                  </label>
                                  <label class="star" style={{ color: "#fc0" }}>
                                    &#9733;
                                  </label>
                                  <label class="star" style={{ color: "#fc0" }}>
                                    &#9733;
                                  </label>
                                </>
                              ) : null}
                            </div>
                            <p>{item.review}</p>
                          </Form>
                        </>
                      ) : null}
                    </>

                    // Replace 'yourField' with the field you want to display from Firestore.
                  ))}

                  {/* <!-- Review Modal --> */}
                  <div
                    class="modal fade"
                    id="exampleModal"
                    tabindex="-1"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                  >
                    <div class="modal-dialog">
                      <div class="modal-content">
                        <div
                          class="modal-header"
                          style={{ backgroundColor: "#1a1a1a", color: "white" }}
                        >
                          <h5 class="modal-title" id="exampleModalLabel">
                            Update Product Review
                          </h5>
                          <button
                            type="button"
                            class="btn-close"
                            data-bs-dismiss="modal"
                            style={{ color: "white" }}
                          >
                            {" "}
                            X
                          </button>
                        </div>
                        <div class="modal-body">
                          <Form>
                            <div class="star-rating">
                              <input
                                type="radio"
                                id="5-stars"
                                name="rating"
                                value="5"
                                checked={rating === 5}
                                onChange={handleRatingChange}
                              />
                              <label class="star">&#9733;</label>
                              <input
                                type="radio"
                                id="4-stars"
                                name="rating"
                                value="4"
                                checked={rating === 4}
                                onChange={handleRatingChange}
                              />
                              <label for="4-stars" class="star">
                                &#9733;
                              </label>
                              <input
                                type="radio"
                                id="3-stars"
                                name="rating"
                                value="3"
                                checked={rating === 3}
                                onChange={handleRatingChange}
                              />
                              <label for="3-stars" class="star">
                                &#9733;
                              </label>
                              <input
                                type="radio"
                                id="2-stars"
                                name="rating"
                                value="2"
                                checked={rating === 2}
                                onChange={handleRatingChange}
                              />
                              <label for="2-stars" class="star">
                                &#9733;
                              </label>
                              <input
                                type="radio"
                                id="1-star"
                                name="rating"
                                value="1"
                                checked={rating === 1}
                                onChange={handleRatingChange}
                              />
                              <label for="1-star" class="star">
                                &#9733;
                              </label>
                            </div>
                            <br></br>
                            <Form.TextArea
                              placeholder="Write your review"
                              value={review}
                              onChange={(e) => setReview(e.target.value)}
                            ></Form.TextArea>
                          </Form>
                        </div>
                        <div class="modal-footer">
                          <Button
                            type="button"
                            class="btn btn-secondary"
                            data-bs-dismiss="modal"
                          >
                            Close
                          </Button>
                          <Button
                            color="google plus"
                            size="small"
                            onClick={handleRatingSubmit}
                          >
                            Submit
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <!-- Review Modal --> */}
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <p>Loading...</p>
      )}
    </>
  );
}

export default OrderedProduct;
