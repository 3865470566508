import React from "react";
import { Link } from "react-router-dom";
function Footer() {
  return (
    <>
      <footer class="footer-section">
        <div class="container relative">
          <div class="row mb-5">
            <div class="col-lg-4">
              <div class="mb-4 footer-logo-wrap">
                <a class="footer-logo">
                  Axom Tea<span>.</span>
                </a>
              </div>
              <p class="mb-4" style={{ color: "#dfdfdf" }}>
                Experience the essence of Assam in every sip – Axom Tea Company,
                where tradition meets unparalleled flavor.
              </p>

              <ul class="list-unstyled custom-social">
                <li>
                  <a href="">
                    <span class="fa fa-brands fa-facebook-f"></span>
                  </a>
                </li>
                <li>
                  <a href="">
                    <span class="fa fa-brands fa-twitter"></span>
                  </a>
                </li>
                <li>
                  <a href="" target="_blank">
                    <span class="fa fa-brands fa-instagram"></span>
                  </a>
                </li>
                {/* <li>
                  <a href="">
                    <span class="fa fa-brands fa-linkedin"></span>
                  </a>
                </li> */}
              </ul>
            </div>

            <div class="col-lg-8">
              <div class="row links-wrap">
                <div class="col-6 col-sm-6 col-md-3">
                  <ul class="list-unstyled">
                    <li>
                      <Link to="/aboutus">About us</Link>
                    </li>
                    <li>
                      <Link to="/cartItems">Cart</Link>
                    </li>
                    <li>
                      <Link to="/products">Products</Link>
                    </li>
                    <li>
                      <Link to="/contact_us">Contact us</Link>
                    </li>
                  </ul>
                </div>

                <div class="col-6 col-sm-6 col-md-3">
                  <ul class="list-unstyled">
                    <li>
                      <a href="">24/7 Support</a>
                    </li>
                    <li>
                      <a href="">Shipping</a>
                    </li>
                    <li>
                      <a href="">Return Policy</a>
                    </li>
                  </ul>
                </div>

                <div class="col-6 col-sm-6 col-md-3">
                  <ul class="list-unstyled">
                    <li>
                      <a href="">Testimonials</a>
                    </li>
                    <li>
                      <Link to="/privacy_policy">Privacy Policy</Link>
                    </li>
                  </ul>
                </div>

                <div class="col-6 col-sm-6 col-md-3">
                  <ul class="list-unstyled"></ul>
                </div>
              </div>
            </div>
          </div>

          <div class="border-top copyright">
            <div class="row pt-4">
              <div class="col-lg-6">
                <p
                  class="mb-2 text-center text-lg-start"
                  style={{ color: "white" }}
                >
                  Copyright &copy;
                  <script>document.write(new Date().getFullYear());</script>.
                  All Rights Reserved. &mdash; Developed by{" "}
                  <a href="">EquiTech</a>
                </p>
              </div>

              <div class="col-lg-6 text-center text-lg-end">
                <ul class="list-unstyled d-inline-flex ms-auto">
                  <li class="me-4">
                    <Link to="/terms__conditions">Terms &amp; Conditions</Link>
                  </li>
                  <li>
                    <Link to="/privacy_policy">Privacy Policy</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
