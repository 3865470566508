import React, { useState } from "react";
import { Form, Button } from "semantic-ui-react";
import { Alert } from "react-bootstrap";
import { serverTimestamp } from "firebase/firestore";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import GoToTop from "../../GoToTop";
import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytesResumable,
} from "firebase/storage";

import { storage, db } from "../../Firebase-config";
function AddProducts() {
  const [title, setTitle] = useState("");
  const [price, setPrice] = useState("");
  const [sprice, setSPrice] = useState("");
  const [desc, setDesc] = useState("");
  const [stock, setStock] = useState("");
  const [imageAsset, setImageAsset] = useState(null);
  const [alertStatus, setAlertStatus] = useState(null);
  const [msg, setMsg] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [variants, setVariants] = useState([{ type: "", variantStock: "" }]);

  const uploadImage = (e) => {
    setIsLoading(true);
    const imageFile = e.target.files[0];
    const storageRef = ref(storage, `Products/${Date.now()}-${imageFile.name}`);
    const uploadTask = uploadBytesResumable(storageRef, imageFile);

    uploadTask.on(
      "state_change",
      (snapshot) => {
        const uploadProgress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      },
      (error) => {
        console.log(error);

        setAlertStatus("Error while uploading : Try Again!");
        setTimeout(() => {
          setIsLoading(false);
          setAlertStatus("");
        }, 4000);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setImageAsset(downloadURL);
          setIsLoading(false);

          setMsg("Image uploaded successfully!");

          setTimeout(() => {
            setMsg("");
          }, 4000);
        });
      }
    );
  };

  const deleteImage = () => {
    setIsLoading(true);
    const deleteRef = ref(storage, imageAsset);
    deleteObject(deleteRef).then(() => {
      setImageAsset(null);
      setIsLoading(false);

      setAlertStatus("Image deleted successfully!");
      setTimeout(() => {
        setAlertStatus("");
      }, 4000);
    });
  };

  const handleVariantChange = (index, field, value) => {
    const updatedVariants = [...variants];
    updatedVariants[index][field] = value;
    setVariants(updatedVariants);
  };

  const addVariant = (e) => {
    e.preventDefault(); // Prevent the default form submission behavior

    setVariants([
      ...variants,
      {
        type: "",
        variantStock: "",
      },
    ]);
  };

  const removeVariant = (index) => {
    const updatedVariants = [...variants];
    updatedVariants.splice(index, 1);
    setVariants(updatedVariants);
  };

  const saveDetails = async () => {
    setIsLoading(true);

    try {
      if (
        !title ||
        !variants.some((v) => v.type && v.variantStock) ||
        !imageAsset
      ) {
        // Check if any variant has empty fields
        setAlertStatus("Required fields can't be empty");
        setTimeout(() => {
          setAlertStatus("");
        }, 4000);
      } else {
        const productRef = await db.collection("Products").add({
          title: title,
          desc: desc,
          price: price,
          sprice: sprice,
          imageURL: imageAsset,
          timestamp: serverTimestamp(),
        });

        // Save variants to the sub-collection
        const variantsCollection = db
          .collection("Products")
          .doc(productRef.id)
          .collection("types");

        variants.forEach(async (variant) => {
          await variantsCollection.add({
            type: variant.type,
            variantStock: variant.variantStock,
          });
        });

        // Update the product document to include the document ID
        await db.collection("Products").doc(productRef.id).update({
          id: productRef.id,
        });

        setIsLoading(false);
        setMsg("Product and variants added successfully!");

        // Reset state for other fields
        setTitle("");
        setPrice("");
        setSPrice("");
        setDesc("");
        setImageAsset("");
        setVariants([{ type: "", variantStock: "" }]);

        setTimeout(() => {
          setMsg("");
        }, 4000);
      }
    } catch (error) {
      console.log(error);

      setAlertStatus("Error while uploading: Try Again!");
      setTimeout(() => {
        setIsLoading(false);
        setAlertStatus("");
      }, 4000);
    }
  };

  return (
    <>
      <GoToTop />
      <div style={{ padding: "5px", marginTop: "13vh" }}>
        <div
          className="container-fluid"
          style={{
            backgroundColor: "white",
            padding: "15px",
            textAlign: "left",
            maxWidth: "185vh",
          }}
        >
          <Form class="ui form" onSubmit={saveDetails}>
            <h2 class="ui dividing header">ADD PRODUCTS</h2>
            {msg && <Alert variant="success">{msg}</Alert>}
            {alertStatus && <Alert variant="danger">{alertStatus}</Alert>}

            <div class="field">
              <div class="row">
                <div class="col-lg-4">
                  <div
                    className="container"
                    style={{
                      height: "40vh",
                      border: "1px solid grey",
                      borderRadius: "5px",
                    }}
                  >
                    {isLoading ? (
                      <div
                        className="container text-center"
                        style={{ marginTop: "18vh" }}
                      >
                        <p style={{ color: "orange" }}>Loading...</p>
                      </div>
                    ) : (
                      <>
                        {!imageAsset ? (
                          <>
                            <div
                              className="container text-center"
                              style={{ marginTop: "18vh" }}
                            >
                              <p style={{ color: "black" }}>Image Preview</p>
                            </div>
                          </>
                        ) : (
                          <>
                            <div>
                              <img
                                src={imageAsset}
                                style={{
                                  height: "39.7vh",
                                  width: "100%",
                                  objectFit: "cover",
                                }}
                                alt="pho"
                              />
                              <button
                                style={{
                                  position: "absolute",
                                  margin: "0 0 0 -12px",
                                }}
                                className="btn btn-danger"
                                onClick={deleteImage}
                              >
                                <i className="fa fa-trash"></i>
                              </button>
                            </div>
                          </>
                        )}
                      </>
                    )}
                  </div>
                  <br></br>
                  <Form.Input
                    label="Product Image"
                    type="file"
                    name="uploadimage"
                    accept="image/*"
                    onChange={uploadImage}
                    placeholder="Upload Product Photo"
                  />
                </div>

                <div class="col-lg-8">
                  <div class="row">
                    <div class="col-lg-6 my-2">
                      <Form.Input
                        label="Product Name"
                        type="text"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        name="name"
                        placeholder="Enter Product Name"
                      />
                    </div>
                    <div class="col-lg-3 my-2">
                      <Form.Input
                        label="Product Price (50gm)"
                        type="number"
                        name="price"
                        value={price}
                        onChange={(e) => setPrice(e.target.value)}
                        placeholder="Enter Product Price"
                      />
                    </div>

                    <div class="col-lg-3 my-2">
                      <Form.Input
                        label="Selling Price (50gm)"
                        type="number"
                        name="sprice"
                        value={sprice}
                        onChange={(e) => setSPrice(e.target.value)}
                        placeholder="Enter Selling Price"
                      />
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-lg-12 my-2">
                      <ReactQuill
                        theme="snow"
                        name="desc"
                        style={{ height: "30vh" }}
                        value={desc}
                        onChange={setDesc}
                      />
                    </div>
                    <div style={{ height: "6vh" }}></div>

                    <div class="row">
                      {variants.map((variant, index) => (
                        <div class="col-lg-3 my-2" key={index}>
                          <div>
                            <Form.Input
                              label={`Type ${index + 1}`}
                              type="text"
                              value={variant.type}
                              onChange={(e) =>
                                handleVariantChange(
                                  index,
                                  "type",
                                  e.target.value
                                )
                              }
                              placeholder={`Enter Variant ${index + 1} Name`}
                            />
                          </div>

                          <div>
                            <Form.Field
                              label={`Variant ${index + 1} Stock`}
                              control="select"
                              value={variant.variantStock}
                              onChange={(e) =>
                                handleVariantChange(
                                  index,
                                  "variantStock",
                                  e.target.value
                                )
                              }
                              name={`variantStock_${index}`}
                            >
                              <option value="">--Select--</option>
                              <option value="Available">Available</option>
                              <option value="Out Of Stock">Out Of Stock</option>
                            </Form.Field>
                          </div>
                          <div>
                            <button
                              className="btn btn-danger"
                              onClick={() => removeVariant(index)}
                            >
                              Remove Variant
                            </button>
                          </div>
                        </div>
                      ))}
                    </div>

                    <div>
                      <button
                        className="btn btn-primary"
                        onClick={(e) => addVariant(e)}
                      >
                        Add Variant
                      </button>
                    </div>
                  </div>

                  <div class="row my-2"></div>

                  {!(title && price && imageAsset) ? (
                    <>
                      <Button
                        disabled
                        color="linkedin"
                        className="ui button my-2"
                        tabindex="0"
                      >
                        Add Product
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button
                        type="submit"
                        color="linkedin"
                        className="ui button my-2"
                        tabindex="0"
                      >
                        Add Product
                      </Button>
                    </>
                  )}
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
}

export default AddProducts;
