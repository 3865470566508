import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { doc, getDoc, onSnapshot } from "firebase/firestore";
import { auth, db } from "../Firebase-config";
import GoToTop from "../GoToTop";
function OrderHistory() {
  const navigate = useNavigate();
  const [userData, setUserData] = useState("");
  const userId = auth.currentUser ? auth.currentUser.uid : null;
  useEffect(() => {
    console.log("User ID:", userId);
    const fetchUserData = async () => {
      if (userId) {
        try {
          const userDocRef = db.collection("Allusers").doc(userId);
          const userDoc = await userDocRef.get();

          if (userDoc.exists) {
            const userData = userDoc.data();
            setUserData(userData);
          } else {
            console.log("No user document found!");
          }
        } catch (error) {
          console.log("Error getting user document:", error);
        }
      }
    };

    // Call the functions to fetch the data
    fetchUserData();
  }, [userId]);
  // console.log(user);
  const [isLoading, setIsLoading] = useState(true);
  const [orders, setOrders] = useState([]);
  useEffect(() => {
    const unsub = onSnapshot(
      db.collectionGroup("ProductsHistory"),
      (snapshot) => {
        let list = [];
        snapshot.docs.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() });
        });
        setOrders(list);
        setIsLoading(false);
      },

      (error) => {
        console.error(error);
        setIsLoading(false);
      }
    );
    return () => {
      unsub();
    };
  }, []);

  const formatTimestamp = (posted) => {
    const date = posted.toDate();
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    return date.toLocaleString("en-US", options);
  };

  return (
    <>
      <GoToTop />
      {/* <!-- Start Hero Section --> */}

      <div class="container" style={{ marginTop: "18vh" }}>
        <h2 style={{ color: "green" }}>Order History</h2>
      </div>

      {/* <!-- End Hero Section --> */}

      <div className="container my-3">
        {orders.length > 0 ? (
          <>
            {orders.map((item) => (
              <>
                {item.uid === userId ? (
                  <>
                    <div
                      onClick={() =>
                        navigate(
                          `/order_item/${item.id}?UID=${item.ID}&IDD=${item.orderIDD}`
                        )
                      }
                      class="row p-2 bg-white border rounded my-2"
                      style={{ maxWidth: "100vh", cursor: "pointer" }}
                    >
                      <div class="col-3 mt-1" style={{ maxWidth: "20vh" }}>
                        <img
                          class="img-fluid img-responsive rounded product-image"
                          src={item.imageURL}
                          style={{
                            maxHeight: "15vh",
                            width: "100%",
                            objectFit: "cover",
                          }}
                        />
                      </div>
                      <div class="col-7 mt-1">
                        <h5 style={{ color: "#545454" }}>{item.title}</h5>

                        <div class="d-flex flex-row align-items-center my-1">
                          <h4
                            class="mr-1"
                            style={{ fontSize: "17px", color: "red" }}
                          >
                            ₹{item.TotalProductPrice}.00{" "}
                            <span style={{ color: "grey", fontSize: "13px" }}>
                              Qty: {item.qty}
                            </span>
                          </h4>
                        </div>

                        <div class="d-flex flex-column mt-2">
                          <a style={{ fontSize: "11px" }}>
                            Expected Delivery: <b> {item.deliveryday}</b>{" "}
                          </a>

                          <a style={{ fontSize: "11px" }}>
                            Status:{" "}
                            <b>
                              {" "}
                              {item.status === "Cancelled" ||
                              item.status === "Refunded" ? (
                                <span style={{ color: "red" }}>
                                  Order Cancelled
                                </span>
                              ) : (
                                <>
                                  {(!item.Ashipped || item.Ashipped === "") &&
                                  (!item.Atransit || item.Atransit === "") &&
                                  (!item.Adelivered ||
                                    item.Adelivered === "") ? (
                                    <i
                                      style={{
                                        color: "green",
                                        fontWeight: "normal",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Ordered
                                    </i>
                                  ) : null}
                                  {item.Ashipped === "active" &&
                                  (!item.Atransit || item.Atransit === "") &&
                                  (!item.Adelivered ||
                                    item.Adelivered === "") ? (
                                    <i
                                      style={{
                                        color: "green",
                                        fontWeight: "normal",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Shipped
                                    </i>
                                  ) : null}
                                  {item.Ashipped === "active" &&
                                  item.Atransit === "active" &&
                                  (!item.Adelivered ||
                                    item.Adelivered === "") ? (
                                    <i
                                      style={{
                                        color: "green",
                                        fontWeight: "normal",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      In Transit
                                    </i>
                                  ) : null}
                                  {item.Ashipped === "active" &&
                                  item.Atransit === "active" &&
                                  item.Adelivered === "active" ? (
                                    <i
                                      style={{
                                        color: "green",
                                        fontWeight: "normal",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Delivered
                                    </i>
                                  ) : null}
                                </>
                              )}
                            </b>
                          </a>
                        </div>
                      </div>
                    </div>
                  </>
                ) : null}
              </>
            ))}
          </>
        ) : null}
      </div>
    </>
  );
}

export default OrderHistory;
