import React, { useState, useEffect } from "react";
import { db, auth } from "../../Firebase-config";
import { Button, Modal } from "semantic-ui-react";

function ModalEditStock({ open, id, setOpen, title, imageURL }) {
  const [userData, setUserData] = useState(null);
  const [variants, setVariants] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [msg, setMsg] = useState("");
  const [alertStatus, setAlertStatus] = useState("");
  const [variantDeleted, setVariantDeleted] = useState(false);

  const userId = auth.currentUser ? auth.currentUser.uid : null;

  const fetchVariants = async () => {
    try {
      const variantsSnapshot = await db
        .collection("Products")
        .doc(id)
        .collection("types")
        .get();

      const variantsData = variantsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setVariants(variantsData);
    } catch (error) {
      console.error("Error fetching variants:", error);
    }
  };

  useEffect(() => {
    const fetchUserData = async () => {
      if (userId) {
        try {
          const userDocRef = db.collection("Allusers").doc(userId);
          const userDoc = await userDocRef.get();

          if (userDoc.exists) {
            const userData = userDoc.data();
            setUserData(userData);
          } else {
            console.log("No user document found!");
          }
        } catch (error) {
          console.log("Error getting user document:", error);
        }
      }
    };

    // Call the fetchUserData function
    fetchUserData();

    // Call the fetchVariants function
    fetchVariants();
  }, [userId, id]);

  const handleVariantChange = (index, field, value) => {
    const updatedVariants = [...variants];
    updatedVariants[index][field] = value;
    setVariants(updatedVariants);
  };

  const removeVariant = async (index) => {
    const updatedVariants = [...variants];
    const removedVariant = updatedVariants.splice(index, 1)[0];

    if (removedVariant.id) {
      try {
        // Remove the variant from Firestore
        await db
          .collection("Products")
          .doc(id)
          .collection("types")
          .doc(removedVariant.id)
          .delete();

        // Update the local state without the removed variant
        setVariants(updatedVariants);
      } catch (error) {
        console.error("Error removing variant from Firestore:", error);
      }
    } else {
      setVariants(updatedVariants);
    }

    // Set a flag to indicate that a variant has been deleted
    setVariantDeleted(true);
    setTimeout(() => {
      setVariantDeleted(false);
    }, 2000);
  };

  const addVariant = (e) => {
    e.preventDefault();
    setVariants([
      ...variants,
      {
        type: "", // Add your field names here
        variantStock: "", // Add your field names here
        // Add more fields as needed
      },
    ]);
  };

  const confirmVariant = async (index) => {
    try {
      const newVariant = variants[index];

      // Validate the required fields (you can add your validation logic here)
      if (!newVariant.type || !newVariant.variantStock) {
        alert("Please fill out all required fields.");
        return;
      }

      const variantsCollection = db
        .collection("Products")
        .doc(id)
        .collection("types");

      const docRef = await variantsCollection.add({
        type: newVariant.type,
        variantStock: newVariant.variantStock,
        // Add more fields as needed
      });

      // Update the local state with the assigned document ID
      newVariant.id = docRef.id;

      // If needed, you can update the UI to reflect the successful addition
      alert("Variant added successfully!");

      // Optionally, you can reset the input fields or close the modal
      setVariants([...variants.slice(0, index), newVariant]);
    } catch (error) {
      console.error("Error adding variant to Firestore:", error);
      alert("Failed to add variant. Please try again.");
    }
  };

  const saveDetails = async () => {
    setIsLoading(true);

    try {
      const variantsCollection = db
        .collection("Products")
        .doc(id)
        .collection("types");

      for (const variant of variants) {
        await variantsCollection.doc(variant.id).update({
          // Assuming each field is present in the state with the same name
          // Adjust this based on your actual field names
          type: variant.type,
          variantStock: variant.variantStock,
          // Add more fields as needed
        });
      }

      setIsLoading(false);
      setMsg("Product and variants updated successfully!");

      // Refetch variants to update the local state
      fetchVariants();

      setTimeout(() => {
        setMsg("");
        setOpen(false);
      }, 4000);
    } catch (error) {
      console.error("Error while updating:", error);

      setAlertStatus("Error while updating: Try Again!");
      setTimeout(() => {
        setIsLoading(false);
        setAlertStatus("");
      }, 4000);
    }
  };

  return (
    <>
      <Modal
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        class="modal fade"
        style={{
          width: "100%",
          height: "100%",
          marginTop: "-8vh",
          backgroundColor: "transparent",
        }}
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header" id="adminthead">
              <h5 class="modal-title" id="exampleModalLabel">
                Update Stock
              </h5>
              <button
                type="button"
                id="adminthead"
                style={{
                  border: "none",
                }}
                onClick={() => setOpen(false)}
                data-bs-dismiss="modal"
              >
                X
              </button>
            </div>
            <div class="modal-body">
              {variantDeleted && (
                <p style={{ color: "red" }}>Deleted Successfully!</p>
              )}
              <form>
                {variants.map((variant, index) => (
                  <div key={index} className="row">
                    {Object.keys(variant).map(
                      (field) =>
                        // Exclude the 'id' field from rendering
                        field !== "id" && (
                          <div key={field} className="col-md-3 mb-2">
                            <label>
                              {field.charAt(0).toUpperCase() + field.slice(1)}:
                            </label>
                            {field === "variantStock" ? ( // Check if the field is 'variantStock'
                              <select
                                className="form-control w-100"
                                value={variant[field]}
                                onChange={(e) =>
                                  handleVariantChange(
                                    index,
                                    field,
                                    e.target.value
                                  )
                                }
                              >
                                <option value="">--</option>
                                <option value="Available">Available</option>
                                <option value="Out of Stock">
                                  Out of Stock
                                </option>

                                {/* Add more options as needed */}
                              </select>
                            ) : (
                              <input
                                className="form-control w-100"
                                type="text"
                                value={variant[field]}
                                onChange={(e) =>
                                  handleVariantChange(
                                    index,
                                    field,
                                    e.target.value
                                  )
                                }
                              />
                            )}
                          </div>
                        )
                    )}
                    {!variant.id && ( // Render Confirm button only if id is not present
                      <div className="col-md-2 mb-2">
                        <button
                          type="button"
                          onClick={() => confirmVariant(index)}
                          className="btn btn-success"
                          style={{
                            marginTop: "10px",
                          }}
                        >
                          Add Confirm
                        </button>
                      </div>
                    )}

                    <div className="col-md-2 mb-2">
                      <button
                        type="button"
                        onClick={() => removeVariant(index)}
                        className="btn"
                        style={{
                          backgroundColor: "red",
                          border: "none",
                          marginTop: "10px",
                        }}
                      >
                        Remove
                      </button>
                    </div>
                  </div>
                ))}
                <Button type="button" onClick={addVariant}>
                  Add Variant
                </Button>
              </form>

              {/* Display loading message */}
              {isLoading && <p>Loading...</p>}

              {/* Display success/error message */}
              {msg && <p>{msg}</p>}
              {alertStatus && <p>{alertStatus}</p>}
            </div>

            <div class="modal-footer">
              <Button size="small" onClick={() => setOpen(false)}>
                Cancel
              </Button>

              <Button
                type="button"
                size="small"
                color="green"
                onClick={saveDetails}
              >
                Save Details
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default ModalEditStock;
