import React, { useState } from "react";
import { Button, Modal, Form } from "semantic-ui-react";
import { useNavigate, Link } from "react-router-dom";
import { auth, db } from "../../Firebase-config";
import { Alert } from "react-bootstrap";

import { createUserWithEmailAndPassword } from "firebase/auth";

function ModalRegister({ open, setOpen }) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loader, setLoader] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [loaderBtn, setLoaderBtn] = useState(false);
  const navigate = useNavigate();

  const handleSignUp = (e) => {
    e.preventDefault();
    setLoader("Creating Account...");
    setLoaderBtn(true);
    createUserWithEmailAndPassword(auth, email, password)
      .then((credentials) => {
        db.collection("Allusers")
          .doc(credentials.user.uid)
          .set({
            name: name,
            email: email,
            password: password,
            uid: credentials.user.uid,
          })
          .then(() => {
            setSuccessMsg("Hurray! Your account has been created successfully");
            setLoader("");
            setName("");
            setEmail("");
            setPassword("");
            setErrorMsg("");
            setLoaderBtn(true);

            setTimeout(() => {
              setSuccessMsg("");
              setOpen(false);
              setLoaderBtn(false);
              window.location.reload();
            }, 3000);
          })
          .catch((error) => {
            setErrorMsg(error.message);
            setLoader("");
            setLoaderBtn(false);
          });
      })
      .catch((error) => {
        setErrorMsg(error.message);
        setLoader("");
      });
  };

  return (
    <>
      <Modal
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        class="modal fade"
        style={{
          width: "100%",
          height: "100%",
          marginTop: "-2vh",
          backgroundColor: "transparent",
        }}
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div
              class="modal-header"
              style={{ backgroundColor: "green", color: "white" }}
            >
              <h5 class="modal-title" id="exampleModalLabel">
                Create a New Account
              </h5>
              <button
                type="button"
                style={{
                  color: "white",
                  backgroundColor: "green",
                  border: "1px solid White",
                }}
                onClick={() => setOpen(false)}
                data-bs-dismiss="modal"
              >
                X
              </button>
            </div>
            <div class="modal-body">
              <div class="card mb-3">
                <div class="card-body">
                  <div
                    class="pb-2"
                    style={{ marginBottom: "15px", textAlign: "center" }}
                  ></div>
                  {successMsg && (
                    <Alert
                      variant="success"
                      style={{ fontSize: "13.5px", textAlign: "center" }}
                    >
                      {successMsg}
                    </Alert>
                  )}
                  {errorMsg && (
                    <Alert
                      variant="danger"
                      style={{ fontSize: "13.5px", textAlign: "center" }}
                    >
                      {errorMsg}
                    </Alert>
                  )}
                  <form
                    class="row g-3 needs-validation"
                    onSubmit={handleSignUp}
                  >
                    <div class="col-12">
                      <label for="yourUsername" class="form-label">
                        Name
                      </label>
                      <div class="input-group has-validation">
                        <input
                          type="text"
                          value={name}
                          placeholder="Enter your name"
                          onChange={(e) => setName(e.target.value)}
                          class="form-control"
                          required
                        />
                        <span class="input-group-text" id="inputGroupPrepend">
                          <i class="fa fa-user" aria-hidden="true"></i>
                        </span>
                      </div>
                    </div>

                    <div class="col-12">
                      <label for="yourUsername" class="form-label">
                        Email
                      </label>
                      <div class="input-group has-validation">
                        <input
                          type="email"
                          value={email}
                          placeholder="Enter your registered email"
                          onChange={(e) => setEmail(e.target.value)}
                          class="form-control"
                          required
                        />
                        <span class="input-group-text" id="inputGroupPrepend">
                          <i class="fa fa-envelope" aria-hidden="true"></i>
                        </span>
                      </div>
                    </div>

                    <div class="col-12">
                      <label for="yourPassword" class="form-label">
                        Password
                      </label>
                      <div class="input-group has-validation">
                        <input
                          type="password"
                          value={password}
                          placeholder="Enter your password"
                          onChange={(e) => setPassword(e.target.value)}
                          class="form-control"
                          required
                        />
                        <span class="input-group-text" id="inputGroupPrepend">
                          <i class="fa fa-eye" aria-hidden="true"></i>
                        </span>
                      </div>
                    </div>

                    <div class="col-12 my-4">
                      {loaderBtn === false ? (
                        <>
                          <Button
                            style={{
                              width: "100%",
                              backgroundColor: "green",
                              color: "white",
                            }}
                            type="submit"
                          >
                            Register
                          </Button>
                        </>
                      ) : (
                        <>
                          <button
                            class="ui loading button"
                            style={{ height: "37px", width: "100%" }}
                          >
                            Loading
                          </button>
                        </>
                      )}
                    </div>
                    <div class="col-12">
                      <p class="small mb-0" style={{ textAlign: "center" }}>
                        Already have an account?{" "}
                        <a
                          style={{ color: "green", cursor: "pointer" }}
                          onClick={() => setOpen(false)}
                        >
                          Login
                        </a>
                      </p>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default ModalRegister;
