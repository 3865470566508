import React, { useState, useEffect } from "react";
import { db, auth } from "../../Firebase-config";
import { Form, Button } from "semantic-ui-react";
import { Table } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { onSnapshot } from "firebase/firestore";
import GoToTop from "../../GoToTop";
const OrderDetails = () => {
  const [userData, setUserData] = useState(null);
  const userId = auth.currentUser ? auth.currentUser.uid : null;
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const { id } = useParams();

  useEffect(() => {
    const fetchUserData = async () => {
      if (userId) {
        try {
          const userDocRef = db.collection("Allusers").doc(userId);
          const userDoc = await userDocRef.get();

          if (userDoc.exists) {
            const userData = userDoc.data();
            setUserData(userData);
          } else {
            navigate("/");
          }
        } catch (error) {
          console.log("Error getting user document:", error);
        }
      }
    };

    // Call the fetchUserData function
    fetchUserData();
  }, [userId]);

  //Orders Fetch
  const [order, setOrder] = useState(null);

  useEffect(() => {
    const unsubscribe = db
      .collection("OrderHistory")
      .doc(id)
      .onSnapshot((doc) => {
        if (doc.exists) {
          setOrder({ id: doc.id, ...doc.data() });
        } else {
          // Handle the case where the document no longer exists
          console.log("Document does not exist");
          setOrder(null); // Reset the order state
        }
      });

    // Clean up the listener when the component unmounts
    return () => {
      unsubscribe();
    };
  }, [id]);

  //Products Fetch
  const [data, setData] = useState([]);
  useEffect(() => {
    const unsub = onSnapshot(
      db.collection("OrderHistory").doc(id).collection("ProductsHistory"),
      (snapshot) => {
        let list = [];
        snapshot.docs.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() });
        });
        setData(list);
        setIsLoading(false);
      },

      (error) => {
        console.error(error);
        setIsLoading(false);
      }
    );
    return () => {
      unsub();
    };
  }, []);
  const [dataToUpdate, setDataToUpdate] = useState(() => {
    const storedData = localStorage.getItem("formData");
    return storedData
      ? JSON.parse(storedData)
      : {
          Ashipped: "",
          Atransit: "",
          Adelivered: "",
        };
  });

  // Fetch and set other data as before
  const [parentData, setParentData] = useState({});
  const [subcollectionData, setSubcollectionData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      // Fetch parentData and subcollectionData as before

      // ...

      try {
        const parentDocRef = db.collection("OrderHistory").doc(id);
        const parentDoc = await parentDocRef.get();

        if (parentDoc.exists) {
          setParentData(parentDoc.data());
        } else {
          console.log("Parent document does not exist.");
        }

        const subcollectionRef = parentDocRef.collection("ProductsHistory");
        const subcollectionSnapshot = await subcollectionRef.get();

        const subcollectionDataArray = [];
        subcollectionSnapshot.forEach((doc) => {
          subcollectionDataArray.push({ id: doc.id, data: doc.data() });
        });
        setSubcollectionData(subcollectionDataArray);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [id]);

  const handleFieldChange = (event) => {
    const { name, value } = event.target;
    const updatedData = { ...dataToUpdate, [name]: value };
    setDataToUpdate(updatedData);

    // Store the updated data in localStorage
    localStorage.setItem("formData", JSON.stringify(updatedData));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      // Update data in the parent collection
      const parentDocRef = db.collection("OrderHistory").doc(id);
      await parentDocRef.update(dataToUpdate);

      // Update data in the subcollection
      const subcollectionRef = parentDocRef.collection("ProductsHistory");
      const subcollectionSnapshot = await subcollectionRef.get();

      const batch = db.batch();

      subcollectionSnapshot.forEach((doc) => {
        const subDocRef = doc.ref;
        batch.update(subDocRef, dataToUpdate);
      });

      await batch.commit();
      console.log(
        "Data updated successfully in parent collection and subcollection."
      );
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };

  return (
    <>
      <GoToTop />
      <div className="container" style={{ marginTop: "14vh" }}>
        <h4 style={{ textAlign: "left" }}>Ordered Products</h4>

        <div
          className="container-fluid"
          style={{
            marginTop: "5vh",
            border: "1px solid #cfcfcf",
            padding: "10px",
            borderRadius: "3px",
          }}
        >
          <h4>Tracking Updates</h4>
          {order ? (
            <>
              <div class="track">
                <div class="step active">
                  <span class="icon">
                    <i class="fa fa-check"></i>{" "}
                  </span>{" "}
                  <span class="text">Order Placed</span>{" "}
                </div>
                <div class={`step ${order.Ashipped}`}>
                  <span class="icon">
                    <i class="fa fa-user"></i>{" "}
                  </span>{" "}
                  <span class="text"> Shipped</span>{" "}
                </div>
                <div class={`step ${order.Atransit}`}>
                  <span class="icon">
                    <i class="fa fa-truck"></i>{" "}
                  </span>{" "}
                  <span class="text"> In transit </span>{" "}
                </div>
                <div class={`step ${order.Adelivered}`}>
                  <span class="icon">
                    <i class="fa fa-home"></i>{" "}
                  </span>{" "}
                  <span class="text">Delivered</span>{" "}
                </div>
              </div>

              <hr></hr>
              <Form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-lg my-1">
                    <Form>
                      <Form.Field
                        label="Order Placed"
                        control="select"
                        className="form-control"
                      >
                        <option value="active">Completed</option>
                      </Form.Field>
                    </Form>
                  </div>
                  <div className="col-lg my-1">
                    <Form>
                      <Form.Field
                        label="Shippment"
                        control="select"
                        name="Ashipped"
                        className="form-control"
                        value={dataToUpdate.Ashipped}
                        onChange={handleFieldChange}
                      >
                        <option value="">Pending</option>
                        <option value="active">Completed</option>
                      </Form.Field>
                    </Form>
                  </div>
                  <div className="col-lg  my-1">
                    <Form>
                      {order.Ashipped === "active" ? (
                        <>
                          <Form.Field
                            label="In Transit"
                            control="select"
                            className="form-control"
                            name="Atransit"
                            value={dataToUpdate.Atransit}
                            onChange={handleFieldChange}
                          >
                            <option value="">Pending</option>
                            <option value="active">Completed</option>
                          </Form.Field>
                        </>
                      ) : (
                        <>
                          <Form.Field
                            label="In Transit"
                            control="select"
                            className="form-control"
                            name="Atransit"
                            disabled
                          >
                            <option value="">Pending</option>
                          </Form.Field>
                        </>
                      )}
                    </Form>
                  </div>
                  <div className="col-lg  my-1">
                    <Form>
                      {order.Atransit === "active" ? (
                        <>
                          <Form.Field
                            label="Delivery"
                            control="select"
                            className="form-control"
                            name="Adelivered"
                            value={dataToUpdate.Adelivered}
                            onChange={handleFieldChange}
                          >
                            <option value="">Pending</option>
                            <option value="active">Completed</option>
                          </Form.Field>
                        </>
                      ) : (
                        <>
                          <Form.Field
                            label="Delivery"
                            control="select"
                            className="form-control"
                            disabled
                          >
                            <option value="">Pending</option>
                            <option value="active">Completed</option>
                          </Form.Field>
                        </>
                      )}
                    </Form>
                  </div>
                </div>
                <Button
                  style={{ marginTop: "5px" }}
                  color="green"
                  type="submit"
                >
                  Confirm
                </Button>
              </Form>
            </>
          ) : (
            <p>Loading...</p>
          )}
        </div>

        <Table
          responsive
          striped
          size="md"
          bordered
          style={{ marginTop: "4px", fontSize: "13px" }}
        >
          <thead id="adminthead">
            <tr>
              <th>#</th>
              <th>Order ID</th>
              <th>Image</th>
              <th>Product Name</th>
              <th>Price</th>
              <th
                style={{
                  textAlign: "center",
                }}
              >
                Quantity
              </th>
              <th
                style={{
                  textAlign: "center",
                }}
              >
                Type
              </th>
              <th>Total Price</th>
              <th>Delivery Status</th>
            </tr>
          </thead>
          <tbody>
            {data.length > 0 ? (
              <>
                {data.map((item, index) => (
                  <tr key={item.id}>
                    <th scope="row" style={{ width: "3vh" }}>
                      {index + 1}
                    </th>
                    <td scope="row" style={{ width: "3vh", fontSize: "11px" }}>
                      {item.id}
                    </td>
                    <td
                      style={{
                        width: "14vh",
                      }}
                    >
                      <img src={item.imageURL} style={{ width: "12vh" }} />
                    </td>
                    <td
                      style={{
                        minWidth: "18vh",
                      }}
                    >
                      {item.title}
                    </td>
                    <td
                      style={{
                        minWidth: "12vh",

                        textAlign: "left",
                      }}
                    >
                      ₹{item.sprice}.00
                    </td>

                    <td style={{ minWidth: "5vh", textAlign: "center" }}>
                      {item.qty}
                    </td>

                    <td style={{ minWidth: "5vh", textAlign: "center" }}>
                      {item.type}gm
                    </td>

                    <td style={{ minWidth: "13vh", fontWeight: "bold" }}>
                      ₹{item.TotalProductPrice}.00
                    </td>

                    <td
                      style={{
                        minWidth: "13vh",
                        fontWeight: "bold",
                      }}
                    >
                      {item.status === "Cancelled" ||
                      item.status === "Refunded" ? (
                        <span style={{ color: "red" }}>Order Cancelled</span>
                      ) : (
                        <>
                          <i style={{ color: "grey", fontWeight: "normal" }}>
                            Shipped:{" "}
                          </i>
                          {item.Ashipped === "" || !item.Ashipped ? (
                            <span style={{ color: "orange" }}>Pending</span>
                          ) : (
                            <span style={{ color: "green" }}>Completed</span>
                          )}
                          <br></br>
                          <i style={{ color: "grey", fontWeight: "normal" }}>
                            Transit:{" "}
                          </i>
                          {item.Atransit === "" || !item.Atransit ? (
                            <span style={{ color: "orange" }}>Pending</span>
                          ) : (
                            <span style={{ color: "green" }}>Completed</span>
                          )}
                          <br></br>
                          <i style={{ color: "grey", fontWeight: "normal" }}>
                            Delivery:{" "}
                          </i>
                          {item.Adelivered === "" || !item.Adelivered ? (
                            <span style={{ color: "orange" }}>Pending</span>
                          ) : (
                            <span style={{ color: "green" }}>Completed</span>
                          )}
                        </>
                      )}
                    </td>
                  </tr>
                ))}
              </>
            ) : (
              <tr>
                <td colSpan="10" style={{ textAlign: "center", color: "red" }}>
                  No Record Found
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    </>
  );
};

export default OrderDetails;
