import React, { useState } from "react";
import ModalProduct from "./ModalProductView";
import { useNavigate } from "react-router-dom";
export const IndividualProduct = ({ individualProduct, addToCart }) => {
  const navigate = useNavigate();

  const handleViewProduct = () => {
    // Use history.push to navigate to the product details page
    navigate(`/product/${individualProduct.id}`);
  };

  // console.log(individualProduct);

  const [openA, setOpenA] = useState(false);
  const [modalA, setModalA] = useState({});

  const handleView = (individualProduct) => {
    setOpenA(true);
    setModalA(individualProduct);
  };

  const [msg, setMsg] = useState(null);
  const handleAddToCart = () => {
    addToCart(individualProduct);
    setMsg("Added to Cart!");
    setTimeout(() => {
      setMsg("");
    }, 2000);
  };

  let percen = 100 - (individualProduct.sprice / individualProduct.price) * 100;
  return (
    <>
      <div class="col-lg-2 my-2" id="productPC">
        <div class="cardd">
          <div class="image-container">
            <div class="first">
              <div class="d-flex justify-content-between align-items-center">
                <span class="discount">{percen.toFixed(0)}% OFF</span>
                {/* <span class="wishlist">
                  <i class="fa fa-heart-o"></i>
                </span> */}
              </div>
            </div>

            <img
              src={individualProduct.imageURL}
              class="img-fluid rounded thumbnail-image"
            />
          </div>

          <div class="p-2">
            <h5
              class="dress-name"
              // onClick={() => handleView(individualProduct)}
            >
              {individualProduct.title.slice(0, 22)}..
            </h5>
            {/* {openA && (
              <ModalProduct
                open={openA}
                addToCart={addToCart}
                individualProduct={individualProduct}
                setOpen={setOpenA}
                {...modalA}
              />
            )} */}

            <div class="d-flex flex-column mb-2">
              <span class="new-price">
                ₹{individualProduct.sprice}.00{" "}
                <s class="old-price">₹{individualProduct.price}.00</s>{" "}
                <i class="old-price">per 50gm</i>
              </span>
            </div>

            <div class="d-flex justify-content-between align-items-center pt-1">
              {/* <div>
                <i class="fa fa-star-o rating-star"></i>
                <span class="rating-number">4.8</span>
              </div> */}

              <button
                class="btn"
                onClick={handleViewProduct}
                style={{ width: "100%" }}
              >
                View Product
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="col-6 my-2" id="productMB">
        <div class="cardd">
          <div class="image-container">
            <div class="first">
              <div class="d-flex justify-content-between align-items-center">
                <span class="discount">{percen.toFixed(0)}% OFF</span>
                {/* <span class="wishlist">
                  <i class="fa fa-heart-o"></i>
                </span> */}
              </div>
            </div>

            <img
              src={individualProduct.imageURL}
              class="img-fluid rounded thumbnail-image"
            />
          </div>

          <div class="p-2">
            <h5 class="dress-name">
              {individualProduct.title.slice(0, 21)} ..
            </h5>

            <div class="d-flex flex-column mb-2">
              <span class="new-price">
                ₹{individualProduct.sprice}.00{" "}
                <s class="old-price">₹{individualProduct.price}.00</s>{" "}
                <i class="old-price">per 50gm</i>
              </span>
            </div>

            <div class="d-flex justify-content-between align-items-center pt-1">
              {/* <div>
                <i class="fa fa-star-o rating-star"></i>
                <span class="rating-number">4.8</span>
              </div> */}

              {/* {individualProduct.stock === "Out Of Stock" ? (
                <>
                  <button
                    class="btn"
                    style={{
                      width: "100%",
                      backgroundColor: "#fc8383",
                      border: "none",
                    }}
                    disabled
                  >
                    Out Of Stock
                  </button>
                </>
              ) : (
                <>
                  <button
                    class="btn"
                    style={{ width: "100%" }}
                    onClick={handleAddToCart}
                  >
                    + Add to Cart
                  </button>
                </>
              )} */}

              <button
                class="btn"
                onClick={handleViewProduct}
                style={{ width: "100%" }}
              >
                View Product
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="col-4 my-2" id="productTAB">
        <div class="cardd">
          <div class="image-container">
            <div class="first">
              <div class="d-flex justify-content-between align-items-center">
                <span class="discount">{percen.toFixed(0)}% OFF</span>
                {/* <span class="wishlist">
                  <i class="fa fa-heart-o"></i>
                </span> */}
              </div>
            </div>

            <img
              src={individualProduct.imageURL}
              class="img-fluid rounded thumbnail-image"
            />
          </div>

          <div class="p-2">
            <h5 class="dress-name">{individualProduct.title.slice(0, 22)}..</h5>
            {openA && (
              <ModalProduct
                open={openA}
                addToCart={addToCart}
                individualProduct={individualProduct}
                setOpen={setOpenA}
                {...modalA}
              />
            )}

            <div class="d-flex flex-column mb-2">
              <span class="new-price">
                ₹{individualProduct.sprice}.00{" "}
                <s class="old-price">₹{individualProduct.price}.00</s>{" "}
                <i class="old-price"> per 50gm</i>
              </span>
            </div>

            <div class="d-flex justify-content-between align-items-center pt-1">
              {/* <div>
                <i class="fa fa-star-o rating-star"></i>
                <span class="rating-number">4.8</span>
              </div> */}

              <button
                class="btn"
                onClick={handleViewProduct}
                style={{ width: "100%" }}
              >
                View Product
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
