import React, {useState, useEffect} from "react";
import {db, auth} from "../../Firebase-config";
import GoToTop from "../../GoToTop";
function Profile() {
  const [data, setData] = useState([]);
  const [userData, setUserData] = useState([]);
  const uid = auth.currentUser.uid;
  useEffect(() => {
    // Create a reference to the Firestore collection
    const collectionRef = db
      .collection("BuyerCart")
      .doc("Items")
      .collection("Cart " + uid);

    // Use the onSnapshot method to listen for changes in the collection
    const unsubscribe = collectionRef.onSnapshot((snapshot) => {
      const newData = snapshot.docs.map((doc) => ({id: doc.id, ...doc.data()}));
      setData(newData);
    });

    // Return a function that unsubscribes from the onSnapshot listener when the component unmounts
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const uid = auth.currentUser.uid;

    // Create a reference to the user document in Firestore
    const userRef = db.collection("Allusers").doc(uid);

    // Subscribe to changes to the user document in Firestore
    const unsubscribe = userRef.onSnapshot((doc) => {
      if (doc.exists) {
        // If the document exists, update the component state with its data
        setUserData(doc.data());
      } else {
        // If the document doesn't exist, set the component state to null
        setUserData(null);
      }
    });

    // Unsubscribe from changes to the user document when the component unmounts
    return () => unsubscribe();
  }, []);

  // Div Shows and hide
  const [showDiv1, setShowDiv1] = useState(true);
  const [showDiv2, setShowDiv2] = useState(false);

  const handleButtonClick1 = () => {
    setShowDiv1(true);
    setShowDiv2(false);
  };

  const handleButtonClick2 = () => {
    setShowDiv1(false);
    setShowDiv2(true);
  };

  return (
    <>
      <GoToTop />
      <div
        className="container"
        style={{
          marginTop: "16vh",
          backgroundColor: "white",
          padding: "12px",
          minHeight: "50vh",
          textAlign: "left",
          maxWidth: "150vh",
        }}
      >
        <div className="row">
          <div className="col-lg-3">
            <div className="container-fluid">
              <div
                className="container-fluid"
                onClick={handleButtonClick1}
                style={{
                  backgroundColor: "#ebebeb",
                  padding: "15px",
                  cursor: "pointer",
                }}
              >
                <h5>My Profile</h5>
              </div>

              <div
                className="container-fluid my-2"
                onClick={handleButtonClick2}
                style={{
                  backgroundColor: "#ebebeb",
                  padding: "15px",
                  cursor: "pointer",
                }}
              >
                <h5>Order History</h5>
              </div>
            </div>
          </div>
          <div className="col-lg-9">
            {/* Order History */}

            {showDiv2 && (
              <div className="container">
                <h3 style={{color: "orange"}}>Order History</h3>
                <hr></hr>
                {data.map((item) => (
                  <div
                    className="row my-2"
                    key={item.id}
                    style={{backgroundColor: "#ebebeb", maxWidth: "120vh"}}
                  >
                    <div className="col-lg-2 my-2">
                      <img
                        style={{width: "100%", maxHeight: "25vh"}}
                        src={item.imageURL}
                        alt="img"
                      />
                    </div>
                    <div className="col-lg-5 my-2">
                      <h4>{item.title}</h4>
                      <p style={{fontSize: "10px", color: "grey"}}>
                        POD ID: <b style={{color: "black"}}>#{item.ID}</b>
                      </p>
                      <p style={{fontSize: "10px", color: "grey"}}>
                        Price: <b style={{color: "black"}}>{item.price}</b>
                      </p>
                      <p style={{fontSize: "10px", color: "grey"}}>
                        Quantity: <b style={{color: "black"}}>{item.qty}</b>
                      </p>
                    </div>

                    <div className="col-lg-5"></div>
                  </div>
                ))}
              </div>
            )}
            {/* Order History */}

            {showDiv1 && (
              <div className="container">
                <h3 style={{color: "orange"}}>My Profile</h3>
                <hr></hr>
                <div>
                  <p>{userData.name}</p>
                  <p>{userData.email}</p>
                  <p>{userData.password}</p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Profile;
