import React, { useEffect, useState } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { db, auth } from "../../Firebase-config";
import "./Productdetails.css";
import { serverTimestamp } from "firebase/firestore";
import { Form } from "semantic-ui-react";
import { Alert } from "react-bootstrap";
import ModalLogin from "../Authentications/ModalLogin";
const ProductDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [product, setProduct] = useState(null);
  const [type, setType] = useState([]);
  const [uid, setUid] = useState(null);
  const [selectedOption, setSelectedOption] = useState("50");
  const [added, setAdded] = useState(false);
  const [loginalert, setLoginAlert] = useState(false);

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        setUid(user.uid);
      }
    });
  }, []);

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const querySnapshot = await db
          .collection("Products")
          .doc(id)
          .collection("types")
          .get();

        const optionsData = querySnapshot.docs.map((doc) => {
          const isOutOfStock = doc.data().variantStock === "Out of Stock";
          const text =
            doc.data().variantStock === "Available"
              ? `${doc.data().type}gm`
              : `${doc.data().type}gm (${doc.data().variantStock})`;
          return {
            key: doc.id,
            value: doc.data().type,
            text: text,
            disabled: isOutOfStock,
            className: isOutOfStock ? "out-of-stock" : "", // Add class for styling
          };
        });

        setType(optionsData);
        setSelectedOption("50"); // Set the default selected option
      } catch (error) {
        console.error("Error fetching options:", error);
      }
    };

    fetchOptions();
  }, [id]);

  const handleTypeChange = (e, { value }) => {
    setSelectedOption(value);
  };

  useEffect(() => {
    const fetchProductDetails = async () => {
      try {
        const productDoc = await db.collection("Products").doc(id).get();
        if (productDoc.exists) {
          setProduct(productDoc.data());
        } else {
          console.error("Product not found");
        }
      } catch (error) {
        console.error("Error fetching product details:", error);
      }
    };

    fetchProductDetails();
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (uid !== null) {
      try {
        const cartRef = db
          .collection("CartItems")
          .doc("Items")
          .collection("Cart " + uid)
          .doc(id + selectedOption)
          .set({
            ID: id + selectedOption,
            type: selectedOption,
            imageURL: product.imageURL,
            desc: product.desc,
            price: (product.price * parseFloat(selectedOption)) / 50,
            sprice: (product.sprice * parseFloat(selectedOption)) / 50,
            title: product.title,
            qty: 1,
            timestamp: serverTimestamp(),
            TotalProductPrice:
              (product.sprice * parseFloat(selectedOption)) / 50,
            MProductPrice: (product.price * parseFloat(selectedOption)) / 50,
            uid: uid,
          });

        setAdded(true);
        setTimeout(() => {
          setAdded(false);
        }, 2000);

        console.log("Product added to Firestore successfully!");
      } catch (error) {
        console.error("Error adding product to Firestore:", error);
      }
    } else {
      setLoginAlert(true);
    }
  };

  const [openP, setOpenP] = useState(false);
  const [modalapplyP, setModalApplyP] = useState({});
  const handleLogin = () => {
    setOpenP(true);
    setModalApplyP();
  };

  return (
    <div>
      {product ? (
        <div>
          {/* <h2>{product.title}</h2>
          <img src={product.imageURL} alt={product.title} />
          <p>Price: ₹{product.sprice}.00</p>
          <p>Original Price: ₹{product.price}.00</p> */}

          <div class="container" style={{ marginTop: "18vh" }}>
            <div class="container-fliud">
              <div class="wrapper row">
                <div class="preview col-md-6">
                  <div class="preview-pic tab-content">
                    <div class="tab-pane active" id="pic-1">
                      <img src={product.imageURL} alt={product.title} />
                    </div>
                    <ul class="preview-thumbnail nav nav-tabs">
                      <li class="active">
                        <a data-target="#pic-1" data-toggle="tab">
                          <img src={product.imageURL} alt={product.title} />
                        </a>
                      </li>

                      <li>
                        <a data-target="#pic-5" data-toggle="tab">
                          <img src={product.imageURL2} alt={product.title} />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="details col-md-6">
                  <h3 class="product-title">{product.title}</h3>
                  <div class="rating">
                    <div class="stars">
                      <span class="fa fa-star checked"></span>
                      <span class="fa fa-star checked"></span>
                      <span class="fa fa-star checked"></span>
                      <span class="fa fa-star checked"></span>
                      <span class="fa fa-star checked"></span>
                    </div>
                    <span class="review-no">6 reviews </span>
                  </div>

                  <h4 class="price" style={{ marginTop: "0" }}>
                    <b
                      style={{
                        fontSize: "18px",
                        fontWeight: "800",
                        fontFamily: "Franklin Gothic Medium",
                      }}
                    >
                      ₹{(product.sprice * parseFloat(selectedOption)) / 50}.00
                    </b>{" "}
                    <s style={{ color: "grey", fontSize: "11px" }}>
                      ₹{(product.price * parseFloat(selectedOption)) / 50}.00
                    </s>
                  </h4>
                  <form onSubmit={handleSubmit}>
                    <Form.Select
                      options={type}
                      value={selectedOption}
                      onChange={handleTypeChange}
                    />

                    {loginalert && (
                      <>
                        <br></br>{" "}
                        <Alert
                          onClick={() => handleLogin()}
                          variant="warning"
                          style={{ textAlign: "center", cursor: "pointer" }}
                        >
                          You are <b>NOT</b> logged in. Please Login <a>here</a>
                          !
                        </Alert>
                        {openP && (
                          <ModalLogin
                            open={openP}
                            setOpen={setOpenP}
                            // handleDelete={handleDelete}
                            {...modalapplyP}
                          />
                        )}
                      </>
                    )}

                    {added && (
                      <>
                        <br></br>{" "}
                        <Alert
                          variant="success"
                          style={{ textAlign: "center" }}
                        >
                          Item added to cart.
                        </Alert>
                      </>
                    )}

                    <button
                      class="btn"
                      type="submit"
                      style={{
                        marginTop: "10px",
                        width: "100%",
                        height: "50px",
                      }}
                    >
                      <i class="fa fa-shopping-cart"></i> Add to Cart
                    </button>

                    <h3>Product Details:</h3>
                    <div
                      class="product-description"
                      dangerouslySetInnerHTML={{ __html: product.desc }}
                    />
                  </form>
                </div>
              </div>
            </div>
          </div>
          <br></br>
          {/* Add more details as needed */}
        </div>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default ProductDetails;
