import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ProductView } from "./ProductView";
import { db, auth } from "../../Firebase-config";
import GoToTop from "../../GoToTop";
import EmptyCart from "../assets/img/emptyCart.jpg";
function Products() {
  const navigate = useNavigate();
  // gettin current user uid
  function GetUserUid() {
    const [uid, setUid] = useState(null);
    useEffect(() => {
      auth.onAuthStateChanged((user) => {
        if (user) {
          setUid(user.uid);
        }
      });
    }, []);
    return uid;
  }

  const uid = GetUserUid();

  // state of products
  const [products, setProducts] = useState([]);

  // getting products function
  const getProducts = async () => {
    const products = await db
      .collection("Products")
      .orderBy("timestamp", "desc")
      .get();
    const productsArray = [];
    for (var snap of products.docs) {
      var data = snap.data();
      data.ID = snap.id;
      productsArray.push({
        ...data,
      });
      if (productsArray.length === products.docs.length) {
        setProducts(productsArray);
      }
    }
  };

  useEffect(() => {
    getProducts();
  }, []);

  let Product;
  const addToCart = (product) => {
    if (uid !== null) {
      // console.log(product);
      Product = product;
      Product["qty"] = 1;
      Product["TotalProductPrice"] = Product.qty * Product.sprice;
      Product["MProductPrice"] = Product.qty * Product.price;
      const updatedProduct = { ...Product, uid: uid };
      db.collection("CartItems")
        .doc("Items")
        .collection("Cart " + uid)
        .doc(product.ID)
        .set(updatedProduct)
        .then(() => {
          console.log("Successfully added to cart");
        });
    } else {
      navigate("/login_auth");
    }
  };

  return (
    <>
      <GoToTop />
      {/* <!-- Start Hero Section --> */}

      <div class="container" style={{ marginTop: "18vh" }}>
        <h2 style={{ color: "green" }}>Explore Our Products</h2>
      </div>
      {/* <!-- End Hero Section --> */}

      {products.length > 0 && (
        <div class="untree_co-section product-section before-footer-section">
          <div class="container">
            <div class="row">
              <ProductView products={products} addToCart={addToCart} />
            </div>
          </div>
        </div>
      )}
      {products.length < 1 && (
        <div
          className="container-fluid"
          style={{
            textAlign: "center",
            padding: "10px",
            color: "red",
            marginTop: "5vh",
            marginBottom: "10vh",
          }}
        >
          <h4>
            <img src={EmptyCart} style={{ maxWidth: "50vh", width: "100%" }} />
            <h5>Ooops..! No Products Available</h5>
          </h4>
        </div>
      )}
    </>
  );
}
export default Products;
