import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ProductView } from "./ProductView";
import { db, auth } from "../../Firebase-config";

function Product2() {
  const navigate = useNavigate();
  // gettin current user uid
  function GetUserUid() {
    const [uid, setUid] = useState(null);
    useEffect(() => {
      auth.onAuthStateChanged((user) => {
        if (user) {
          setUid(user.uid);
        }
      });
    }, []);
    return uid;
  }

  const uid = GetUserUid();

  // state of products
  const [products, setProducts] = useState([]);

  // getting products function
  const getProducts = async () => {
    const products = await db
      .collection("Products")
      .orderBy("timestamp", "desc")
      .limit(4)
      .get();
    const productsArray = [];
    for (var snap of products.docs) {
      var data = snap.data();
      data.ID = snap.id;
      productsArray.push({
        ...data,
      });
      if (productsArray.length === products.docs.length) {
        setProducts(productsArray);
      }
    }
  };

  useEffect(() => {
    getProducts();
  }, []);

  let Product;
  const addToCart = (product) => {
    if (uid !== null) {
      // console.log(product);
      Product = product;
      Product["qty"] = 1;
      Product["TotalProductPrice"] = Product.qty * Product.sprice;
      Product["MProductPrice"] = Product.qty * Product.price;
      const updatedProduct = { ...Product, uid: uid };
      db.collection("CartItems")
        .doc("Items")
        .collection("Cart " + uid)
        .doc(product.ID)
        .set(updatedProduct)
        .then(() => {
          console.log("Successfully added to cart");
        });
    } else {
      navigate("/login_auth");
    }
  };

  return (
    <>
      <br></br>
      {products.length > 0 && (
        <ProductView products={products} addToCart={addToCart} />
      )}
      {products.length < 1 && (
        <h5 style={{ textAlign: "center", color: "grey" }}>Loading...</h5>
      )}
    </>
  );
}
export default Product2;
