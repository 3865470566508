import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { auth, db } from "../../Firebase-config";
import GoToTop from "../../GoToTop";
import { serverTimestamp } from "firebase/firestore";

function Address() {
  const [userData, setUserData] = useState("");
  const userId = auth.currentUser ? auth.currentUser.uid : null;
  useEffect(() => {
    const fetchUserData = async () => {
      if (userId) {
        try {
          const userDocRef = db.collection("Allusers").doc(userId);
          const userDoc = await userDocRef.get();

          if (userDoc.exists) {
            const userData = userDoc.data();
            setUserData(userData);
          } else {
            console.log("No user document found!");
          }
        } catch (error) {
          console.log("Error getting user document:", error);
        }
      }
    };

    // Call the functions to fetch the data
    fetchUserData();
  }, [userId]);
  return (
    <>
      <div className="container" style={{ marginTop: "18vh" }}>
        <h5
          class="text-black h5"
          style={{
            fontWeight: "bold",
            backgroundColor: "#c8fad5",
            borderRadius: "5px",
            padding: "10px",
          }}
        >
          <i
            style={{ cursor: "pointer", marginRight: "10px" }}
            class="fa fa-angle-double-left"
            aria-hidden="true"
          ></i>
          Manage Address
        </h5>

        <div className="row my-3">
          <h6 style={{ color: "green", fontWeight: "bolder" }}>
            Active Address
          </h6>
        </div>
      </div>
    </>
  );
}

export default Address;
