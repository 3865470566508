import React, { useState, useEffect } from "react";
import { db, auth } from "../Firebase-config";
import { Link } from "react-router-dom";
import ShoppingCart from "./assets/img/cart.svg";
import UserImg from "./assets/img/user.svg";
import logo from "../Assets/img/logo1.svg";
import { useNavigate } from "react-router-dom";
const Navbar = () => {
  const navigate = useNavigate();
  function GetCurrentUser() {
    const [user, setUser] = useState(null);
    useEffect(() => {
      auth.onAuthStateChanged((user) => {
        if (user) {
          db.collection("Allusers")
            .doc(user.uid)
            .get()
            .then((snapshot) => {
              setUser(snapshot.data().name);
            });
        } else {
          setUser(null);
        }
      });
    }, []);
    return user;
  }

  const user = GetCurrentUser();

  const handleLogout = () => {
    auth.signOut().then(() => {
      navigate("/login_auth");
      window.location.reload();
    });
  };

  // state of totalProducts
  const [totalProducts, setTotalProducts] = useState(0);
  // getting cart products
  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        db.collection("CartItems")
          .doc("Items")
          .collection("Cart " + user.uid)
          .onSnapshot((snapshot) => {
            const qty = snapshot.docs.length;
            setTotalProducts(qty);
          });
      }
    });
  }, []);

  const [userData, setUserData] = useState("");
  const userId = auth.currentUser ? auth.currentUser.uid : null;
  useEffect(() => {
    const fetchUserData = async () => {
      if (userId) {
        try {
          const userDocRef = db.collection("Allusers").doc(userId);
          const userDoc = await userDocRef.get();

          if (userDoc.exists) {
            const userData = userDoc.data();
            setUserData(userData);
          } else {
            navigate("/");
          }
        } catch (error) {
          console.log("Error getting user document:", error);
        }
      }
    };

    // Call the fetchUserData function
    fetchUserData();
  }, [userId]);

  const [isContent1Visible, setIsContent1Visible] = useState(false);
  const [isContent2Visible, setIsContent2Visible] = useState(false);

  const handleToggle1 = () => {
    setIsContent1Visible(!isContent1Visible);
    setIsContent2Visible(false); // Close content 2 when content 1 is opened
  };

  const handleToggle2 = () => {
    setIsContent2Visible(!isContent2Visible);
    setIsContent1Visible(false); // Close content 1 when content 2 is opened
  };

  return (
    <>
      <nav
        class="custom-navbar navbar navbar navbar-expand-md navbar-light bg-light fixed-top"
        arial-label="Furni navigation bar"
      >
        <div class="container">
          <a class="navbar-brand">
            <img src={logo} style={{ width: "30vh" }} />
          </a>

          <Link
            to="/cartItems"
            class="nav-link"
            style={{ position: "absolute", right: "10vh", top: "30px" }}
          >
            <img src={ShoppingCart} />
            <span
              class="count-style"
              style={{
                backgroundColor: "green",

                marginLeft: "-7px",
                fontWeight: "bold",
                padding: "3px 4px 4px 6px",
                color: "white",
                height: "23px",
                width: "23px",
                position: "absolute",
                top: "-5px",
                borderRadius: "50%",
              }}
            >
              {" "}
              {totalProducts}
            </span>
          </Link>

          <button
            class="navbar-toggler"
            type="button"
            style={{ marginTop: "2px" }}
            data-bs-toggle="collapse"
            data-bs-target="#navbarsFurni"
            aria-controls="navbarsFurni"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>

          <div class="collapse navbar-collapse" id="navbarsFurni">
            <ul class="custom-navbar-nav navbar-nav ms-auto mb-2 mb-md-0">
              <li class="nav-item active">
                <Link class="nav-link" to="/">
                  Home
                </Link>
              </li>
              <li>
                <Link class="nav-link" to="/products">
                  Shop
                </Link>
              </li>

              <li>
                <Link class="nav-link" to="/aboutus">
                  About Us
                </Link>
              </li>

              <li>
                <Link class="nav-link" to="/contact_us">
                  Contact Us
                </Link>
              </li>
            </ul>

            <ul class="custom-navbar-cta navbar-nav mb-2 mb-md-0 ms-5">
              <li
                style={{
                  border: "1px solid grey",
                  borderRadius: "5px",
                  padding: "0 5px 0 5px",
                }}
              >
                <a
                  class="nav-link dropdown-toggle"
                  onClick={handleToggle1}
                  type="button"
                >
                  <img src={UserImg} /> {userData.name}
                </a>

                {isContent1Visible && (
                  <ul
                    style={{
                      position: "absolute",
                      backgroundColor: "white",
                      padding: "10px",
                      width: "100%",
                      maxWidth: "400px",
                      listStyle: "none",
                      right: "5px",
                    }}
                  >
                    {!user ? (
                      <>
                        <li>
                          <Link class="dropdown-item" to="/login_auth">
                            Login
                          </Link>
                        </li>
                      </>
                    ) : (
                      <>
                        {userData.role && userData.role === "Admin" ? (
                          <>
                            <li onClick={handleToggle1}>
                              <Link
                                class="dropdown-item"
                                to="/adminDashboard"
                                style={{ color: "red" }}
                              >
                                Admin Dashboard
                              </Link>
                            </li>
                          </>
                        ) : null}

                        <li onClick={handleToggle1}>
                          <Link class="dropdown-item" to="/my_orders">
                            Order History
                          </Link>
                        </li>
                        <li onClick={handleToggle1}>
                          <Link class="dropdown-item" to="/">
                            Account
                          </Link>
                        </li>

                        <li onClick={handleToggle1}>
                          <a
                            onClick={handleLogout}
                            class="dropdown-item"
                            style={{ color: "red", cursor: "pointer" }}
                          >
                            Log Out
                          </a>
                        </li>
                      </>
                    )}
                  </ul>
                )}
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
