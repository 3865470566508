import React, { useState, useEffect } from "react";
import { db, auth } from "../../Firebase-config";
import { Link } from "react-router-dom";
import ShoppingCart from "../assets/img/cart.svg";
import UserImg from "../assets/img/user.svg";
import logo from "../../Assets/img/logo1.svg";
import { useNavigate } from "react-router-dom";
const Navbar = () => {
  const navigate = useNavigate();
  function GetCurrentUser() {
    const [user, setUser] = useState(null);
    useEffect(() => {
      auth.onAuthStateChanged((user) => {
        if (user) {
          db.collection("Allusers")
            .doc(user.uid)
            .get()
            .then((snapshot) => {
              setUser(snapshot.data().name);
            });
        } else {
          setUser(null);
        }
      });
    }, []);
    return user;
  }

  const user = GetCurrentUser();

  const handleLogout = () => {
    auth.signOut().then(() => {
      navigate("/login_auth");
      window.location.reload();
    });
  };

  const [userData, setUserData] = useState("");
  const userId = auth.currentUser ? auth.currentUser.uid : null;
  useEffect(() => {
    const fetchUserData = async () => {
      if (userId) {
        try {
          const userDocRef = db.collection("Allusers").doc(userId);
          const userDoc = await userDocRef.get();

          if (userDoc.exists) {
            const userData = userDoc.data();
            setUserData(userData);
          } else {
            navigate("/");
          }
        } catch (error) {
          console.log("Error getting user document:", error);
        }
      }
    };

    // Call the fetchUserData function
    fetchUserData();
  }, [userId]);

  return (
    <>
      <nav
        class="custom-navbar navbar navbar navbar-expand-md navbar-light bg-light fixed-top"
        arial-label="Furni navigation bar"
      >
        <div class="container">
          <a class="navbar-brand">
            AxomTea<span>.</span>
          </a>

          <button
            class="navbar-toggler"
            type="button"
            style={{ marginTop: "2px" }}
            data-bs-toggle="collapse"
            data-bs-target="#navbarsFurni"
            aria-controls="navbarsFurni"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>

          <div class="collapse navbar-collapse" id="navbarsFurni">
            <ul class="custom-navbar-nav navbar-nav ms-auto mb-2 mb-md-0">
              <li>
                <Link class="nav-link" to="/current_orders_">
                  Orders
                </Link>
              </li>

              <li>
                <Link class="nav-link" to="/productslist">
                  Products
                </Link>
              </li>
            </ul>

            <ul class="custom-navbar-cta navbar-nav mb-2 mb-md-0 ms-5">
              <li>
                <a
                  class="nav-link dropdown-toggle"
                  id="dropdownMenuButton8"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  type="button"
                >
                  <img src={UserImg} />
                </a>

                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton8">
                  {!user ? (
                    <>
                      <li>
                        <Link class="dropdown-item" to="/login_auth">
                          Login
                        </Link>
                      </li>
                    </>
                  ) : (
                    <>
                      <li>
                        <Link class="dropdown-item" to="/">
                          Account
                        </Link>
                      </li>

                      <li>
                        <a
                          onClick={handleLogout}
                          class="dropdown-item"
                          style={{ color: "red" }}
                        >
                          Log Out
                        </a>
                      </li>
                    </>
                  )}
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
