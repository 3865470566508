import React from "react";

import { auth, db } from "../../Firebase-config";

export const IndividualCartProduct = ({
  cartProduct,
  cartProductIncrease,
  cartProductDecrease,
}) => {
  const handleCartProductIncrease = () => {
    cartProductIncrease(cartProduct);
  };

  const handleCartProductDecrease = () => {
    cartProductDecrease(cartProduct);
  };
  const handleCartProductDelete = () => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        db.collection("CartItems")
          .doc("Items")
          .collection("Cart " + user.uid)
          .doc(cartProduct.ID)
          .delete()
          .then(() => {
            console.log("successfully deleted");
          });
      }
    });
  };

  let percen = 100 - (cartProduct.sprice / cartProduct.price) * 100;
  return (
    // <tbody
    //   style={{
    //     fontSize: "12px",
    //     backgroundColor: "white",
    //   }}
    // >
    //   <tr>
    //     <td style={{ textAlign: "center" }}>
    //       <img
    //         src={cartProduct.imageURL}
    //         alt="product"
    //         style={{ width: "70px", height: "70px", objectFit: "cover" }}
    //       />
    //     </td>
    //     <td
    //       style={{
    //         textAlign: "left",
    //         minWidth: "23vh",
    //       }}
    //     >
    //       <b style={{ fontSize: "14px" }}>{cartProduct.title}</b>
    //       <br></br>{" "}
    //       <p
    //         style={{
    //           color: "grey",
    //           fontSize: "10px",
    //           minWidth: "55vh",
    //           lineHeight: "13px",
    //         }}
    //       >
    //         {cartProduct.desc.slice(0, 200)} ...
    //       </p>
    //     </td>

    //     <td
    //       style={{
    //         textAlign: "center",
    //         padding: "19px 20px 0 20px",

    //         minWidth: "12vh",
    //       }}
    //     >
    //       <b>₹ {cartProduct.sprice}</b>
    //     </td>

    //     <td
    //       style={{
    //         textAlign: "center",
    //         minWidth: "17vh",
    //         padding: "19px 20px 0 20px",
    //       }}
    //     >
    //       <div
    //         className="row"
    //         style={{
    //           textAlign: "center",
    //           backgroundColor: "#e3e3e3",
    //           borderRadius: "5px",
    //         }}
    //       >
    //         <div
    //           onClick={handleCartProductDecrease}
    //           className="col"
    //           style={{
    //             padding: "5px",
    //             color: "#ffa787",
    //             cursor: "pointer",
    //           }}
    //         >
    //           <i class="fa fa-minus" aria-hidden="true"></i>
    //         </div>
    //         <div
    //           className="col"
    //           style={{
    //             padding: "5px",
    //           }}
    //         >
    //           {cartProduct.qty}
    //         </div>
    //         <div
    //           onClick={handleCartProductIncrease}
    //           className="col"
    //           style={{
    //             padding: "5px",
    //             color: "#6f9e6f",
    //             cursor: "pointer",
    //           }}
    //         >
    //           <i class="fa fa-plus" aria-hidden="true"></i>
    //         </div>
    //       </div>
    //     </td>
    //     <td
    //       style={{
    //         textAlign: "center",
    //         minWidth: "12vh",
    //         padding: "19px 20px 0 20px",
    //       }}
    //     >
    //       <b>₹ {cartProduct.TotalProductPrice}</b>
    //     </td>
    //     <td style={{ textAlign: "center" }}>
    //       <i
    //         style={{
    //           color: "red",
    //           fontSize: "18px",
    //           padding: "19px 20px 0 20px",
    //           cursor: "pointer",
    //         }}
    //         onClick={handleCartProductDelete}
    //         className="fa fa-trash"
    //       ></i>
    //     </td>
    //   </tr>
    // </tbody>
    <>
      <div
        class="row p-2 rounded my-2"
        style={{ margin: "1px", border: "1px solid #e6e6e6" }}
      >
        <div class="col-4 " style={{ maxWidth: "20vh" }}>
          <img
            src={cartProduct.imageURL}
            style={{
              maxHeight: "19vh",
              width: "100%",
              objectFit: "cover",
              borderRadius: "5px",
            }}
          />

          <div
            className="row my-2"
            style={{
              textAlign: "center",
              border: "1px solid #e6e6e6",
              borderRadius: "5px",
            }}
          >
            <div
              onClick={handleCartProductDecrease}
              className="col"
              style={{
                padding: "3px",

                cursor: "pointer",
              }}
            >
              <i class="fa fa-minus" aria-hidden="true"></i>
            </div>
            <div
              className="col"
              style={{
                padding: "3px",
              }}
            >
              {cartProduct.qty}
            </div>

            <div
              onClick={handleCartProductIncrease}
              className="col"
              style={{
                padding: "3px",

                cursor: "pointer",
              }}
            >
              <i class="fa fa-plus" aria-hidden="true"></i>
            </div>
          </div>
        </div>
        <div class="col-8 mt-1">
          <p
            style={{
              color: "#000",
              fontWeight: "bolder",
              fontSize: "17px",
            }}
          >
            {cartProduct.title}{" "}
            <i style={{ color: "grey", fontSize: "10px" }}>
              [{cartProduct.type}gm]
            </i>
          </p>

          <div
            class="d-flex flex-row align-items-center"
            style={{ marginTop: "-10px" }}
          >
            <h4 class="mr-1" style={{ fontSize: "17px", color: "black" }}>
              ₹{cartProduct.TotalProductPrice}.00{" "}
              <s style={{ color: "grey", fontSize: "12px" }}>
                ₹{cartProduct.MProductPrice}.00
              </s>{" "}
              <span
                style={{
                  backgroundColor: "green",
                  color: "white",
                  padding: "5px",
                  fontSize: "12px",
                }}
              >
                {percen.toFixed(0)}% Off
              </span>
            </h4>
          </div>
          <span style={{ fontSize: "10px" }}>
            Delivery: <b style={{ fontSize: "11px" }}>7-10 Bussiness Days</b>
          </span>
          <br></br>
          <p
            onClick={handleCartProductDelete}
            style={{
              fontWeight: "bold",
              color: "red",
              marginTop: "3.5px",
              cursor: "pointer",
            }}
          >
            REMOVE
          </p>
          {/* <div class="d-flex flex-column mt-2">
            <a style={{ fontSize: "11px" }}>
              Expected Delivery: <b>25 September, 2023</b>{" "}
            </a>
          </div> */}
        </div>
      </div>
    </>
  );
};
