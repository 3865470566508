import React, { useState, useEffect } from "react";
import { db, auth } from "../../Firebase-config";
import { Button, Modal, Form } from "semantic-ui-react";
import { Alert } from "react-bootstrap";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
function ModalEditProduct({
  open,
  id,
  setOpen,
  title,
  imageURL,
  sprice,
  price,
  desc,
}) {
  const [userData, setUserData] = useState(null);

  const [updatedtitle, setTitle] = useState(title);
  const [updatedPrice, setPrice] = useState(price);
  const [updatedSPrice, setSPrice] = useState(sprice);
  const [updatedDesc, setDesc] = useState(desc);

  const userId = auth.currentUser ? auth.currentUser.uid : null;
  useEffect(() => {
    const fetchUserData = async () => {
      if (userId) {
        try {
          const userDocRef = db.collection("Allusers").doc(userId);
          const userDoc = await userDocRef.get();

          if (userDoc.exists) {
            const userData = userDoc.data();
            setUserData(userData);
          } else {
            console.log("No user document found!");
          }
        } catch (error) {
          console.log("Error getting user document:", error);
        }
      }
    };

    // Call the fetchUserData function
    fetchUserData();
  }, [userId]);

  const [success, setsuccessLoader] = useState("");
  ///Submit
  const handleSubmit = async () => {
    setsuccessLoader("");

    try {
      // Create a storage reference

      db.collection("Products").doc(id).update({
        title: updatedtitle,

        sprice: updatedSPrice,
        price: updatedPrice,
        desc: updatedDesc,
      });

      // Reset form fields and close the modal

      setsuccessLoader("Updated successfully!");
      setTitle("");
      setDesc("");

      setPrice("");
      setSPrice("");

      setTimeout(() => {
        setsuccessLoader("");
        setOpen(false);
      }, 2000);
    } catch (error) {
      console.log("Error submitting applied project:", error);
    }
  };

  return (
    <>
      <Modal
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        class="modal fade"
        style={{
          width: "100%",
          height: "100%",
          marginTop: "-8vh",
          backgroundColor: "transparent",
        }}
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header" id="adminthead">
              <h5 class="modal-title" id="exampleModalLabel">
                Edit Product
              </h5>
              <button
                type="button"
                id="adminthead"
                style={{
                  border: "none",
                }}
                onClick={() => setOpen(false)}
                data-bs-dismiss="modal"
              >
                X
              </button>
            </div>
            <div class="modal-body">
              <Form>
                <div class="row">
                  <div class="col-lg-4">
                    <img
                      src={imageURL}
                      style={{
                        height: "40vh",
                        width: "100%",
                        objectFit: "cover",
                      }}
                      alt="pho"
                    />
                  </div>

                  <div class="col-lg-8">
                    <div class="row">
                      <div class="col-lg-6 my-2">
                        <Form.Input
                          label="Product Name"
                          type="text"
                          value={updatedtitle}
                          onChange={(e) => setTitle(e.target.value)}
                          name="name"
                          placeholder="Enter Product Name"
                        />
                      </div>

                      <div class="col-lg-3 my-2">
                        <Form.Input
                          label="Product Price"
                          type="number"
                          name="price"
                          value={updatedPrice}
                          onChange={(e) => setPrice(e.target.value)}
                          placeholder="Enter Product Price"
                        />
                      </div>

                      <div class="col-lg-3 my-2">
                        <Form.Input
                          label="Selling Price"
                          type="number"
                          name="sprice"
                          value={updatedSPrice}
                          onChange={(e) => setSPrice(e.target.value)}
                          placeholder="Enter Selling Price"
                        />
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-lg-12 my-2">
                        <ReactQuill
                          theme="snow"
                          name="desc"
                          style={{ height: "30vh" }}
                          value={updatedDesc}
                          onChange={setDesc}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            </div>

            <div className="container">
              {success && (
                <Alert style={{ textAlign: "center" }} variant="success">
                  {success}
                </Alert>
              )}
            </div>

            <div class="modal-footer">
              <Button size="small" onClick={() => setOpen(false)}>
                Cancel
              </Button>

              <Button
                type="submit"
                color="linkedin"
                className="ui button my-2"
                tabindex="0"
                onClick={handleSubmit}
              >
                Update Product
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default ModalEditProduct;
