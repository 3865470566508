import React, { useState } from "react";
import { Button, Form } from "semantic-ui-react";
import { useNavigate, Link } from "react-router-dom";
import { auth } from "../../Firebase-config";
import { Alert } from "react-bootstrap";
import GoToTop from "../../GoToTop";

import { signInWithEmailAndPassword } from "firebase/auth";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loader, setLoader] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [loaderBtn, setLoaderBtn] = useState(false);
  const navigate = useNavigate();
  const handleSignIn = (e) => {
    e.preventDefault();
    setLoader("Verifying credentials...");
    setLoaderBtn(true);
    signInWithEmailAndPassword(auth, email, password)
      .then(() => {
        setSuccessMsg("Login Successful ! Please wait...");
        setLoader("");
        setEmail("");
        setPassword("");
        setErrorMsg("");
        setLoaderBtn(true);

        setTimeout(() => {
          setSuccessMsg("");
          navigate("/");
          setLoaderBtn(false);
        }, 3000);
      })
      .catch((error) => {
        setErrorMsg(error.message);
        setLoader("");
        setLoaderBtn(false);
      });
  };

  return (
    <>
      <GoToTop />
      <section class="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">
              <div class="card mb-3">
                <div class="card-body">
                  <div
                    class="pb-2"
                    style={{ marginBottom: "15px", textAlign: "center" }}
                  >
                    <h5 class="card-title text-center pb-0 fs-3">
                      Login to Your Account
                    </h5>
                  </div>
                  {successMsg && (
                    <Alert
                      variant="success"
                      style={{ fontSize: "13.5px", textAlign: "center" }}
                    >
                      {successMsg}
                    </Alert>
                  )}
                  {errorMsg && (
                    <Alert
                      variant="danger"
                      style={{ fontSize: "13.5px", textAlign: "center" }}
                    >
                      {errorMsg}
                    </Alert>
                  )}
                  <form
                    class="row g-3 needs-validation"
                    onSubmit={handleSignIn}
                  >
                    <div class="col-12">
                      <label for="yourUsername" class="form-label">
                        Email
                      </label>
                      <div class="input-group has-validation">
                        <input
                          type="email"
                          value={email}
                          placeholder="Enter your registered email"
                          onChange={(e) => setEmail(e.target.value)}
                          class="form-control"
                          required
                        />
                        <span class="input-group-text" id="inputGroupPrepend">
                          <i class="fa fa-envelope" aria-hidden="true"></i>
                        </span>
                      </div>
                    </div>

                    <div class="col-12">
                      <label for="yourPassword" class="form-label">
                        Password
                      </label>
                      <div class="input-group has-validation">
                        <input
                          type="password"
                          value={password}
                          placeholder="Enter your password"
                          onChange={(e) => setPassword(e.target.value)}
                          class="form-control"
                          required
                        />
                        <span class="input-group-text" id="inputGroupPrepend">
                          <i class="fa fa-eye" aria-hidden="true"></i>
                        </span>
                      </div>
                    </div>

                    <div class="col-12">
                      <Link to="" style={{ fontSize: "12px" }}>
                        Forgot Password
                      </Link>
                    </div>
                    <div class="col-12 my-4">
                      {loaderBtn === false ? (
                        <>
                          <Button
                            style={{
                              width: "100%",
                              backgroundColor: "green",
                              color: "white",
                            }}
                            type="submit"
                          >
                            Login
                          </Button>
                        </>
                      ) : (
                        <>
                          <button
                            class="ui loading button"
                            style={{ height: "37px", width: "100%" }}
                          >
                            Loading
                          </button>
                        </>
                      )}
                    </div>
                    <div class="col-12">
                      <p class="small mb-0" style={{ textAlign: "center" }}>
                        Don't have account?{" "}
                        <Link to="/register_auth">Register</Link>
                      </p>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Login;
