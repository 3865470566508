import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";
import { getStorage } from "firebase/storage";

var firebaseApp = firebase.initializeApp({
  apiKey: "AIzaSyAsyBFaA9SoCejO3ISmjZ-6z68PmkjkthU",
  authDomain: "assamtea-ea96d.firebaseapp.com",
  projectId: "assamtea-ea96d",
  storageBucket: "assamtea-ea96d.appspot.com",
  messagingSenderId: "897920242946",
  appId: "1:897920242946:web:6b26be674a47534e6c5e1b",
  measurementId: "G-YHH5ZNPQQZ",
});

// Initialize Firebase
var db = firebaseApp.firestore();
export const auth = getAuth(firebaseApp);
export const database = getDatabase(firebaseApp);
export const storage = getStorage(firebaseApp);
export { db };
