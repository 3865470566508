import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { auth, db } from "../../Firebase-config";
import { CartProducts } from "./CartProducts";
import GoToTop from "../../GoToTop";
import { serverTimestamp } from "firebase/firestore";
export const Cart = () => {
  const [userData, setUserData] = useState("");
  const userId = auth.currentUser ? auth.currentUser.uid : null;
  useEffect(() => {
    const fetchUserData = async () => {
      if (userId) {
        try {
          const userDocRef = db.collection("Allusers").doc(userId);
          const userDoc = await userDocRef.get();

          if (userDoc.exists) {
            const userData = userDoc.data();
            setUserData(userData);
          } else {
            console.log("No user document found!");
          }
        } catch (error) {
          console.log("Error getting user document:", error);
        }
      }
    };

    // Call the functions to fetch the data
    fetchUserData();
  }, [userId]);
  // console.log(user);

  // state of cart products
  const [cartProducts, setCartProducts] = useState([]);

  // getting cart products from firestore collection and updating the state
  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        db.collection("CartItems")
          .doc("Items")
          .collection("Cart " + user.uid)
          .onSnapshot((snapshot) => {
            const newCartProduct = snapshot.docs.map((doc) => ({
              ID: doc.id,
              ...doc.data(),
            }));
            setCartProducts(newCartProduct);
          });
      } else {
        console.log("user is not signed in to retrieve cart");
      }
    });
  }, []);

  // console.log(totalQty);

  // getting the TotalProductPrice from cartProducts in a seperate array
  const price = cartProducts.map((cartProduct) => {
    return cartProduct.TotalProductPrice;
  });

  // reducing the price in a single value
  const reducerOfPrice = (accumulator, currentValue) =>
    accumulator + currentValue;

  const [couponInput, setCoupon] = useState("");

  // console.log(cartProducts);

  // global variable
  let Product;

  // cart product increase function
  const cartProductIncrease = (cartProduct) => {
    // console.log(cartProduct);
    Product = cartProduct;
    Product.qty = Product.qty + 1;
    Product.TotalProductPrice = Product.qty * Product.sprice;
    Product.MProductPrice = Product.qty * Product.price;
    // updating in database
    auth.onAuthStateChanged((user) => {
      if (user) {
        db.collection("CartItems")
          .doc("Items")
          .collection("Cart " + user.uid)
          .doc(cartProduct.ID)
          .update(Product)
          .then(() => {
            console.log("increment added");
          });
      } else {
        console.log("user is not logged in to increment");
      }
    });
  };

  // cart product decrease functionality
  const cartProductDecrease = (cartProduct) => {
    Product = cartProduct;
    if (Product.qty > 1) {
      Product.qty = Product.qty - 1;
      Product.TotalProductPrice = Product.qty * Product.sprice;
      Product.MProductPrice = Product.qty * Product.price;
      // updating in database
      auth.onAuthStateChanged((user) => {
        if (user) {
          db.collection("CartItems")
            .doc("Items")
            .collection("Cart " + user.uid)
            .doc(cartProduct.ID)
            .update(Product)
            .then(() => {
              console.log("decrement");
            });
        } else {
          console.log("user is not logged in to decrement");
        }
      });
    }
  };

  ///Razorpay
  const navigate = useNavigate();
  function refreshPage() {
    window.location.reload();
  }
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const today = new Date();
  const time =
    today.getHours().toString().padStart(2, "0") +
    ":" +
    today.getMinutes().toString().padStart(2, "0") +
    ":" +
    today.getSeconds().toString().padStart(2, "0");
  const current = new Date();
  const yearr = current.getFullYear(); // Get the current year
  const month = String(current.getMonth() + 1).padStart(2, "0"); // Get the current month (0-11) and add leading zero if needed
  const day = String(current.getDate()).padStart(2, "0");
  const date = `${yearr}-${month}-${day}`;
  const monthh = monthNames[current.getMonth()];
  // const deliverydate = `${String(current.getDate() + 9).padStart(
  //   2,
  //   "0"
  // )} ${monthh}, ${yearr}`;

  const currentDate = new Date(); // Get the current date
  const deliveryDate = new Date(currentDate.setDate(currentDate.getDate() + 9)); // Add 9 days to the current date

  const dayy = String(deliveryDate.getDate()).padStart(2, "0");
  const monthhh = deliveryDate.toLocaleString("en-US", { month: "short" });
  const year = deliveryDate.getFullYear();

  const deliverydate = `${dayy} ${monthhh}, ${year}`;
  console.log(deliverydate);

  const [loader, setLoader] = useState(false);
  const [status, setStatus] = useState(undefined);

  const [name, setUsername] = useState("");
  const [phone, setPhone] = useState("");
  const [state, setState] = useState("");
  const [address, setAddress] = useState("");
  const [pincode, setPincode] = useState("");

  const totalPrice = price.reduce(reducerOfPrice, 0);
  let delivery;
  // if (totalPrice > 599) {
  //   if (couponInput === "FREE599") {
  //     delivery = 0;
  //   } else {
  //     delivery = 70;
  //   }
  // } else {
  //   delivery = 70;
  // }

  if (state === "") {
    delivery = 0;
  } else if (state === "Assam") {
    delivery = 70;
  } else {
    delivery = 100;
  }

  const TtotalPrice = totalPrice + delivery;

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   setLoader(true);

  //   try {
  //     const newSalesHistoryDocRef = db
  //       .collection("OrderHistory")
  //       .doc(date + "-" + time);

  //     await newSalesHistoryDocRef.set({
  //       name: userData.name,
  //       email: userData.email,
  //       phone: phone,
  //       uid: userData.uid,
  //       address,
  //       pincode,
  //       state,
  //       TtotalPrice,
  //       totalPrice,
  //       delivery,
  //       status: "Pending",
  //       postedBy: userData.name,
  //       deliverydate,
  //       timestamp: serverTimestamp(),
  //     });

  //     const options = {
  //       key: "rzp_live_TZoZERu2Ugd2tO",
  //       key_secret: "NSKVFWtHNAC7iQ7Km7JNOKub",
  //       amount: TtotalPrice * 100,
  //       currency: "INR",
  //       name: "AxomTea",
  //       description: "AxomTea",
  //       prefill: {
  //         name: userData.name,
  //         contact: phone,
  //       },
  //       notes: {
  //         address: "Sivsagar, Assam",
  //       },
  //       theme: {
  //         color: "#008000",
  //       },
  //       handler: async (response) => {
  //         try {
  //           const sourceCollectionRef = db
  //             .collection("CartItems")
  //             .doc("Items")
  //             .collection("Cart " + userId);

  //           const newSalesHistoryDocRef = db
  //             .collection("OrderHistory")
  //             .doc(date + "-" + time);

  //           await newSalesHistoryDocRef.update({
  //             payId: response.razorpay_payment_id,
  //             status: "Paid",
  //           });

  //           const targetCollectionRef =
  //             newSalesHistoryDocRef.collection("ProductsHistory");
  //           const querySnapshot = await sourceCollectionRef.get();

  //           querySnapshot.forEach(async (doc) => {
  //             const data = doc.data();

  //             data.deliveryday = deliverydate;
  //             data.postedtime = serverTimestamp();
  //             data.orderIDD = date + "-" + time;

  //             await targetCollectionRef.add(data);

  //             await sourceCollectionRef.doc(doc.id).delete();
  //           });

  //           navigate("/my_orders");
  //         } catch (error) {
  //           console.error("Error processing payment:", error);
  //         }
  //       },
  //       modal: {
  //         ondismiss: function () {
  //           alert("Payment cancelled");
  //           try {
  //             newSalesHistoryDocRef.update({
  //               status: "Cancelled",
  //             });
  //           } catch (error) {
  //             console.error("Error processing payment:", error);
  //           }
  //         },
  //       },
  //     };

  //     const pay = new window.Razorpay(options);
  //     pay.open();
  //   } catch (error) {
  //     console.error("Error initiating payment:", error);
  //   } finally {
  //     setLoader(false);
  //   }
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);

    try {
      const newSalesHistoryDocRef = db
        .collection("OrderHistory")
        .doc(date + "-" + time);

      await newSalesHistoryDocRef
        .set({
          address: address,
          state: state,
          phone: phone,
          name: name,

          uid: userData.uid,
          address,
          pincode,
          state,
          TtotalPrice,
          totalPrice,
          delivery,
          status: "Pending",
          postedBy: userData.name,
          deliverydate,
          timestamp: serverTimestamp(),
        })
        .then(() => {
          db.collection("Allusers")
            .doc(userId)
            .update({
              address: address || userData.address,
              state: state,
              phone: phone || userData.phone,
              name: name || userData.name,
              pincode: pincode || userData.pincode,
            });
        });

      const sourceCollectionRef = db
        .collection("CartItems")
        .doc("Items")
        .collection("Cart " + userId);

      const targetCollectionRef =
        newSalesHistoryDocRef.collection("ProductsHistory");
      const querySnapshot = await sourceCollectionRef.get(); // Use await here

      const updateCartPromises = querySnapshot.docs.map(async (doc) => {
        const data = doc.data();

        data.deliveryday = deliverydate;
        data.postedtime = serverTimestamp();
        data.orderIDD = date + "-" + time;

        await targetCollectionRef.add(data);
        await sourceCollectionRef.doc(doc.id).delete();
      });

      await Promise.all(updateCartPromises);

      navigate(
        `/payment_page/${userData.uid}?ID=${
          date + "-" + time
        }&TP=${TtotalPrice}&DL=${delivery}&P=${totalPrice}&DD=${deliverydate}&n=${
          userData.name
        }&ad=${address}&st=${state}`
      );
    } catch (error) {
      console.error("Error initiating payment:", error);
    } finally {
      setLoader(false);
    }
  };

  const [showDiv1, setShowDiv1] = useState(true);
  const [showDiv2, setShowDiv2] = useState(false);

  const toggleDivs = () => {
    setShowDiv1(!showDiv1);
    setShowDiv2(!showDiv2);
  };

  return (
    <>
      <GoToTop />
      {/* <!-- Start Hero Section --> */}

      <div class="container" style={{ marginTop: "10vh" }}></div>

      {/* <!-- End Hero Section --> */}

      {/* <!-- Modal --> */}
      <div
        class="modal fade"
        id="exampleModal"
        style={{ marginTop: "-15vh" }}
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div
              class="modal-header"
              style={{ backgroundColor: "green", color: "white" }}
            >
              <h5 class="modal-title" id="exampleModalLabel">
                Available Coupons
              </h5>
              <button
                type="button"
                class="btn-close"
                style={{ color: "white" }}
                data-bs-dismiss="modal"
              >
                X
              </button>
            </div>
            <div class="modal-body">
              {/* <h5>
                *Free shipping on order above{" "}
                <b style={{ color: "red" }}>Rs.599</b>
                <input
                  style={{ marginTop: "8px" }}
                  class="form-control"
                  value="FREE599"
                />
              </h5> */}
              <h4>No Coupons Available</h4>
            </div>
          </div>
        </div>
      </div>
      {cartProducts.length > 0 && (
        <>
          <div class="untree_co-section before-footer-section">
            <form form action="" onSubmit={handleSubmit}>
              <div class="container">
                <div class="row mb-5">
                  {showDiv1 && (
                    <div class="col-md-9">
                      <h5
                        class="text-black h5"
                        style={{
                          fontWeight: "bold",
                          backgroundColor: "#c8fad5",
                          borderRadius: "5px",
                          padding: "10px",
                        }}
                      >
                        Checkout{" "}
                        <i
                          class="fa fa-angle-double-right"
                          aria-hidden="true"
                        ></i>
                      </h5>
                      <div style={{ maxHeight: "75vh", overflowY: "scroll" }}>
                        <CartProducts
                          cartProducts={cartProducts}
                          cartProductIncrease={cartProductIncrease}
                          cartProductDecrease={cartProductDecrease}
                        />
                      </div>
                    </div>
                  )}

                  {/* ///////////Shipping Address */}
                  {showDiv2 && (
                    <div class="col-md-9">
                      <h5
                        class="text-black h5"
                        style={{
                          fontWeight: "bold",
                          backgroundColor: "#c8fad5",
                          borderRadius: "5px",
                          padding: "10px",
                        }}
                      >
                        <i
                          style={{ cursor: "pointer", marginRight: "10px" }}
                          onClick={toggleDivs}
                          class="fa fa-angle-double-left"
                          aria-hidden="true"
                        ></i>
                        Shipping Address
                      </h5>
                      <div className="row" style={{ maxWidth: "90vh" }}>
                        <div class="col-md-6 my-2">
                          <label>* Name</label>
                          <input
                            class="form-control"
                            value={name || userData.name}
                            placeholder="Full name"
                            onChange={(e) => setUsername(e.target.value)}
                            type="text"
                          />
                        </div>
                        <div class="col-md-6 my-2">
                          <label>* Contact No.</label>
                          <input
                            class="form-control"
                            value={phone || userData.phone}
                            placeholder="Contact Number"
                            onChange={(e) => setPhone(e.target.value)}
                            type="tel"
                          />
                        </div>
                        <div class="col-md-6 my-2">
                          <label>* PinCode</label>
                          <input
                            class="form-control"
                            value={pincode || userData.pincode}
                            placeholder="Pincode"
                            onChange={(e) => setPincode(e.target.value)}
                          />
                        </div>
                        <div class="col-md-6 my-2">
                          <label>State</label>
                          <select
                            class="form-control"
                            value={state}
                            onChange={(e) => setState(e.target.value)}
                          >
                            <option option="--">--Select--</option>
                            <option value="Andhra Pradesh">
                              Andhra Pradesh
                            </option>
                            <option value="Andaman and Nicobar Islands">
                              Andaman and Nicobar Islands
                            </option>
                            <option value="Arunachal Pradesh">
                              Arunachal Pradesh
                            </option>
                            <option value="Assam">Assam</option>
                            <option value="Bihar">Bihar</option>
                            <option value="Chandigarh">Chandigarh</option>
                            <option value="Chhattisgarh">Chhattisgarh</option>
                            <option value="Dadar and Nagar Haveli">
                              Dadar and Nagar Haveli
                            </option>
                            <option value="Daman and Diu">Daman and Diu</option>
                            <option value="Delhi">Delhi</option>
                            <option value="Lakshadweep">Lakshadweep</option>
                            <option value="Puducherry">Puducherry</option>
                            <option value="Goa">Goa</option>
                            <option value="Gujarat">Gujarat</option>
                            <option value="Haryana">Haryana</option>
                            <option value="Himachal Pradesh">
                              Himachal Pradesh
                            </option>
                            <option value="Jammu and Kashmir">
                              Jammu and Kashmir
                            </option>
                            <option value="Jharkhand">Jharkhand</option>
                            <option value="Karnataka">Karnataka</option>
                            <option value="Kerala">Kerala</option>
                            <option value="Madhya Pradesh">
                              Madhya Pradesh
                            </option>
                            <option value="Maharashtra">Maharashtra</option>
                            <option value="Manipur">Manipur</option>
                            <option value="Meghalaya">Meghalaya</option>
                            <option value="Mizoram">Mizoram</option>
                            <option value="Nagaland">Nagaland</option>
                            <option value="Odisha">Odisha</option>
                            <option value="Punjab">Punjab</option>
                            <option value="Rajasthan">Rajasthan</option>
                            <option value="Sikkim">Sikkim</option>
                            <option value="Tamil Nadu">Tamil Nadu</option>
                            <option value="Telangana">Telangana</option>
                            <option value="Tripura">Tripura</option>
                            <option value="Uttar Pradesh">Uttar Pradesh</option>
                            <option value="Uttarakhand">Uttarakhand</option>
                            <option value="West Bengal">West Bengal</option>
                          </select>
                        </div>

                        <div>
                          <label>* Address</label>
                          <textarea
                            class="form-control"
                            value={address || userData.address}
                            placeholder="Full Address"
                            onChange={(e) => setAddress(e.target.value)}
                            rows="3"
                          ></textarea>
                          {/* <button class="btn btn-black">Update Address</button> */}
                        </div>
                      </div>
                      <div class="row my-4">
                        <span
                          style={{
                            fontSize: "12px",
                            fontStyle: "italic",
                            color: "red",
                          }}
                        >
                          * Estimated Delivery:{" "}
                          <b style={{ fontSize: "15px", fontStyle: "normal" }}>
                            {deliverydate}
                          </b>
                        </span>{" "}
                      </div>
                    </div>
                  )}

                  <div
                    class="col-md-3"
                    style={{ padding: "5px 20px 20px 20px" }}
                  >
                    <div class="row justify-content-end">
                      <h5 class="text-black h5" style={{ fontWeight: "bold" }}>
                        Payment Summary
                      </h5>
                      <div class="col-md-12">
                        <div
                          className="row my-3"
                          style={{
                            backgroundColor: "#d4fcdf",
                            padding: "10px 5px 10px 5px",
                          }}
                        >
                          <div class="col-4 my-2">
                            <a
                              data-bs-toggle="modal"
                              data-bs-target="#exampleModal"
                              style={{
                                border: "none",
                                cursor: "pointer",
                                backgroundColor: "#1a1a1a",
                                borderRadius: "5px",
                                padding: "8px ",
                                width: "100%",
                                color: "white",
                              }}
                            >
                              Coupons
                            </a>
                          </div>
                          <div class="col-8 mb-3 mb-md-0">
                            <input
                              class="form-control"
                              value={couponInput}
                              onChange={(e) => setCoupon(e.target.value)}
                              placeholder="Coupon Code"
                            />
                          </div>
                        </div>

                        <div class="row" style={{ marginTop: "8px" }}>
                          <div class="col-6">
                            <h5>Subtotal : </h5>
                          </div>
                          <div class="col-6" style={{ textAlign: "right" }}>
                            <h4>₹{totalPrice}.00</h4>
                          </div>
                        </div>
                        <div class="row my-3">
                          <div class="col-6">
                            <h5>Coupon : </h5>
                          </div>
                          <div class="col-6" style={{ textAlign: "right" }}>
                            <h4>₹0.00</h4>
                          </div>
                        </div>
                        <div class="row my-3">
                          <div class="col-6">
                            <h5>
                              Delivery Charges :
                              {/*<br></br> <span>
                                {delivery === 0 ? (
                                  <i
                                    style={{
                                      color: "grey",
                                      fontWeight: "lighter",
                                      fontSize: "12px",
                                    }}
                                  >
                                    Select state
                                  </i>
                                ) : null}
                              </span>{" "} */}
                            </h5>
                          </div>
                          <div class="col-6" style={{ textAlign: "right" }}>
                            <h4>
                              {" "}
                              {delivery === 0 ? (
                                <i
                                  style={{
                                    color: "grey",
                                    fontSize: "11px",
                                    fontWeight: "normal",
                                  }}
                                >
                                  -Select State-
                                </i>
                              ) : (
                                <>₹{delivery}.00</>
                              )}
                            </h4>
                          </div>
                        </div>

                        <div
                          class="row my-3"
                          style={{
                            backgroundColor: "#d4fcdf",
                            padding: "10px 1px 10px 1px",
                          }}
                        >
                          <div class="col-6">
                            <h5>Total Payable : </h5>
                          </div>
                          <div class="col-6" style={{ textAlign: "right" }}>
                            <h4>₹{TtotalPrice}.00</h4>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row my-4">
                      {showDiv2 && (
                        <>
                          {/* {(address.length == 0 &&
                            phone.length == 0 &&
                            state.length == 0 &&
                            pincode.length == 0) ||
                          (userData.phone == undefined &&
                            userData.pincode == undefined) ? (
                            <button
                              style={{ width: "100%", cursor: "not-allowed" }}
                              disabled
                              class="btn btn-black btn-lg  btn-block"
                            >
                              Proceed To Checkout
                            </button>
                          ) : (
                            <button
                              style={{ width: "100%" }}
                              type="submit"
                              class="btn btn-black btn-lg btn-block"
                            >
                              Proceed To Checkout
                            </button>
                          )} */}

                          {state.length == 0 ? (
                            <button
                              style={{ width: "100%", cursor: "not-allowed" }}
                              disabled
                              class="btn btn-black btn-lg  btn-block"
                            >
                              Proceed To Checkout
                            </button>
                          ) : (
                            <button
                              style={{ width: "100%" }}
                              type="submit"
                              class="btn btn-black btn-lg btn-block"
                            >
                              Proceed To Checkout
                            </button>
                          )}

                          <button
                            style={{
                              width: "100%",
                              marginTop: "8px",
                              backgroundColor: "#c2c2c2",
                              border: "none",
                            }}
                            disabled
                            class="btn  btn-block"
                          >
                            Cash On Delivery (Not available)
                          </button>
                        </>
                      )}
                      {showDiv1 && (
                        <a
                          style={{ width: "100%" }}
                          onClick={toggleDivs}
                          class="btn btn-lg  btn-block"
                        >
                          Proceed
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </>
      )}
      {cartProducts.length < 1 && (
        <div className="container-fluid">
          <div
            className="container"
            style={{
              maxWidth: "100vh",
              height: "auto",
              backgroundColor: "white",
              padding: "15px",
            }}
          >
            <img
              style={{ width: "30vh", height: "auto" }}
              alt="cart"
              src="https://img.freepik.com/free-vector/consumer-information-abstract-concept-vector-illustration-consumer-law-privacy-security-policy-financial-information-marketing-service-buyer-protection-online-shopping-abstract-metaphor_335657-2882.jpg?w=740&t=st=1684061855~exp=1684062455~hmac=355d90055eef95a1d8e37537600877a895d05c499ff8a6635526ebe512e12386"
            />
            <h1 style={{ color: "green" }}>Your cart is empty.</h1>

            <p>You can go to home page to view more products</p>

            <Link to="/products">
              <Button>Explore</Button>
            </Link>
          </div>
        </div>
      )}
    </>
  );
};
