import React, { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Form, Button } from "semantic-ui-react";

import { Alert } from "react-bootstrap";
import GoToTop from "../../GoToTop";
import ConfirmModal from "./ConfirmPaymentModal";

function PaymentForm() {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const ID = params.get("ID");
  const TP = params.get("TP");
  const DD = params.get("DD");
  const DL = params.get("DL");
  const P = params.get("P");
  const State = params.get("st");
  const Name = params.get("n");
  const Address = params.get("ad");

  const [openA, setOpenA] = useState(false);
  const [modalA, setModalA] = useState({});

  const handleView = () => {
    setOpenA(true);
    setModalA();
  };

  return (
    <>
      {" "}
      <GoToTop />
      <div class="container" style={{ marginTop: "18vh" }}>
        <Alert variant="warning">
          <b>
            We have recieved your order! Please complete the payment process to
            confirm your Order.
          </b>
        </Alert>
        <h2 style={{ color: "green" }}>Payment Process </h2>
        <div className="row">
          <div className="col-lg-5">
            <div className="card">
              <div
                className="card-header"
                style={{ backgroundColor: "green", color: "white" }}
              >
                <h5>Delivery Details:</h5>
              </div>
              <div className="card-body">
                <h6>
                  Name: <b>{Name}</b>
                </h6>

                <h6>
                  Address: <b>{Address}</b>
                </h6>

                <h6>
                  State: <b>{State}</b>
                </h6>
                <hr />
                <h6>
                  Expected Delivery: <b style={{ color: "red" }}>{DD}</b>
                </h6>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="card">
              <div
                className="card-header"
                style={{ backgroundColor: "green", color: "white" }}
              >
                <h5>Payment Details:</h5>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col">
                    <h6>Total Products Price:</h6>
                  </div>
                  <div className="col" style={{ textAlign: "right" }}>
                    <b>₹{P}.00</b>
                  </div>
                </div>

                <div className="row">
                  <div className="col">
                    <h6> Delivery Charge:</h6>
                  </div>
                  <div className="col" style={{ textAlign: "right" }}>
                    <b>₹{DL}.00</b>
                  </div>
                </div>

                <div
                  className="row"
                  style={{
                    backgroundColor: "green",
                    padding: "5px 0 5px 0",
                    color: "white",
                  }}
                >
                  <div className="col">
                    <h3>Grand Total:</h3>
                  </div>
                  <div className="col" style={{ textAlign: "right" }}>
                    <h3>₹{TP}.00</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/assamtea-ea96d.appspot.com/o/WhatsApp%20Image%202024-01-22%20at%2021.31.13_744759f9.jpg?alt=media&token=10e5747b-2af1-4725-ad99-f6c5be1437e9"
              style={{ width: "100%", marginBottom: "3px" }}
            />

            <input className="form-control" value="wsmraihan69@oksbi" />
          </div>
        </div>
        <div
          className="container"
          style={{ textAlign: "center", marginTop: "7vh" }}
        >
          <button
            type="button"
            style={{ width: "80%" }}
            class="btn btn-success"
            onClick={() => handleView()}
          >
            Payment Completed
          </button>
          {openA && (
            <ConfirmModal open={openA} setOpen={setOpenA} {...modalA} />
          )}
        </div>
        <br /> <br />
        <h6>
          Contact us for any support :{" "}
          <a href="tel:9864928363">+91 Helpline No. 1</a> or{" "}
          <a href="tel:7086952212">+91 Helpline No. 2</a>
        </h6>
      </div>
      <br />
    </>
  );
}

export default PaymentForm;
