import React, { useState, useEffect } from "react";
import { db, auth } from "../../Firebase-config";
import { Button } from "semantic-ui-react";
import { Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { onSnapshot } from "firebase/firestore";
import ModalEdit from "./ModalEditProduct";
import ModalEditStock from "./ModalEditStock";
import GoToTop from "../../GoToTop";
function Products() {
  const [userData, setUserData] = useState(null);
  const userId = auth.currentUser ? auth.currentUser.uid : null;
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchUserData = async () => {
      if (userId) {
        try {
          const userDocRef = db.collection("Allusers").doc(userId);
          const userDoc = await userDocRef.get();

          if (userDoc.exists) {
            const userData = userDoc.data();
            setUserData(userData);
          } else {
            navigate("/");
          }
        } catch (error) {
          console.log("Error getting user document:", error);
        }
      }
    };

    // Call the fetchUserData function
    fetchUserData();
  }, [userId]);

  //Students Fetch
  const [data, setData] = useState([]);
  useEffect(() => {
    const unsub = onSnapshot(
      db.collection("Products").orderBy("timestamp"),
      (snapshot) => {
        let list = [];
        snapshot.docs.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() });
        });
        setData(list);
        setIsLoading(false);
      },

      (error) => {
        console.error(error);
        setIsLoading(false);
      }
    );
    return () => {
      unsub();
    };
  }, []);

  /////////////////Filter

  const [searchTerm, setSearchTerm] = useState("");
  const [searchBy, setSearchBy] = useState("title");

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSearchByChange = (event) => {
    setSearchBy(event.target.value);
  };

  const filteredData = data.filter((item) =>
    item[searchBy].toLowerCase().includes(searchTerm.toLowerCase())
  );

  const formatTimestamp = (payment) => {
    const date = payment.toDate();
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    return date.toLocaleString("en-US", options);
  };

  const [openE, setOpenE] = useState(false);
  const [modalEdit, setModalE] = useState({});

  const handleEdit = (item) => {
    setOpenE(true);
    setModalE(item);
  };

  const [openS, setOpenS] = useState(false);
  const [modalEditS, setModalS] = useState({});

  const handleEditS = (item) => {
    setOpenS(true);
    setModalS(item);
  };

  return (
    <>
      <GoToTop />
      <div className="container" style={{ marginTop: "14vh" }}>
        <h4 style={{ textAlign: "left" }}>My Products</h4>
        <Link to="/addProducts">
          <Button
            style={{ position: "absolute", top: "0", right: "5vh" }}
            color="linkedin"
          >
            Add Product
          </Button>
        </Link>

        <Table
          responsive
          striped
          size="md"
          bordered
          style={{ marginTop: "4px", fontSize: "13px" }}
        >
          <thead id="adminthead">
            <tr>
              <th>#</th>
              <th>Product Image</th>
              <th>Product Name</th>

              <th>MRP (50gm)</th>
              <th>Selling Price (50gm)</th>
              <th style={{ textAlign: "center" }}>Inventory</th>
              <th style={{ textAlign: "center" }}>Action</th>
            </tr>
          </thead>
          <tbody>
            {data.length > 0 ? (
              <>
                {filteredData.map((item, index) => (
                  <tr key={item.id}>
                    <th scope="row" style={{ width: "3vh" }}>
                      {index + 1}
                    </th>
                    <td
                      style={{
                        minWidth: "11vh",
                      }}
                    >
                      <img
                        style={{
                          width: "10vh",
                          height: "12vh",
                          objectFit: "cover",
                        }}
                        src={item.imageURL}
                      />
                    </td>
                    <td
                      style={{
                        minWidth: "20vh",

                        textAlign: "left",
                      }}
                    >
                      {item.title} (50gm)
                    </td>

                    <td style={{ minWidth: "13vh" }}>₹{item.price}</td>
                    <td
                      style={{
                        minWidth: "13vh",

                        fontWeight: "bold",
                      }}
                    >
                      ₹{item.sprice}
                    </td>

                    <td
                      style={{
                        minWidth: "13vh",
                        textAlign: "center",
                        color: "orange",
                        cursor: "pointer",
                        fontWeight: "bold",
                      }}
                      onClick={() => handleEditS(item)}
                    >
                      Manage
                    </td>
                    {openS && (
                      <ModalEditStock
                        open={openS}
                        setOpen={setOpenS}
                        // handleDelete={handleDelete}
                        {...modalEditS}
                      />
                    )}

                    <td
                      style={{
                        minWidth: "10vh",

                        textAlign: "center",
                      }}
                    >
                      {" "}
                      <i
                        style={{
                          fontSize: "17px",

                          margin: "10px",
                          cursor: "pointer",
                        }}
                        onClick={() => handleEdit(item)}
                        class="fa fa-pencil"
                        aria-hidden="true"
                      ></i>
                      {openE && (
                        <ModalEdit
                          open={openE}
                          setOpen={setOpenE}
                          // handleDelete={handleDelete}
                          {...modalEdit}
                        />
                      )}
                      <i
                        style={{
                          fontSize: "17px",
                          color: "red",
                          margin: "10px",
                          cursor: "pointer",
                        }}
                        // onClick={() => handleEdit(item)}
                        class="fa fa-trash"
                        aria-hidden="true"
                      ></i>
                    </td>
                  </tr>
                ))}
              </>
            ) : (
              <tr>
                <td colSpan="10" style={{ textAlign: "center", color: "red" }}>
                  No Record Found
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    </>
  );
}

export default Products;
